import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';

import GetScore from '../components/GetScore';

// Table component of All student tab
export default function AverageScoreTable({list, handleClick}) {
    const [noOfStudents, setNoOfStudents] = useState(0)

    useEffect(() => {
        setNoOfStudents(list.length)
        if (!list[0].student_name) setNoOfStudents(0)
    }, [list])

    const TableBody = () => {
        if (!list[0] || !list[0].student_name){
            return <tr><td colSpan={7} className='border-0 font-size-lg color-grey800 pt-4'>No data yet.</td></tr>
        }

        return list.map((item, index) => {
            return (<tr key={index} className="color-grey800 table-tr align-middle hover-modal" onClick={() => handleClick({id: item.student_id, name: item.student_name})}>
                <td className="fw-bold text-start color-grey900 font-size-md">{item.student_name}</td>
                <td className="position-relative"><img src={process.env.PUBLIC_URL + '/images/star.svg'} alt='star'/><span className="text-icon-star fw-bold">{item.points}</span></td>
                <td className="font-weight-500">{item.avg_book_level}</td>
                <td className="color-grey800">
                    <GetScore type='comprehension' data={item.comprehension_avg} />
                </td>
                <td>
                    <GetScore type='speaking' data={item.speaking_avg} />
                </td>
                <td>
                    <GetScore type='writing' data={item.writing_avg} />
                </td>
                {/* <td className="font-weight-500">{item.overall}</td> */}
            </tr>)
        })
    }

    return (
        <Table className="text-center table-sw">
            <thead className="bg-color curved-top">
                <tr>
                    <td className="text-start border-0 align-middle font-size-lg color-grey900 font-weight500" rowSpan="2">
                        All Students
                        <span className="student_no ms-3">{noOfStudents}</span>
                    </td>
                    <td className="border-0"></td>
                    <td className="color-grey800 font-size-sm" colSpan="5">AVERAGES</td>
                </tr>
                <tr className="color-grey600 font-weight-600">
                    <td className="">Points</td>
                    <td className="">Book Level</td>
                    <td className="">Comprehension</td>
                    <td className="">Speaking</td>
                    <td className="">Writing</td>
                    {/* <td className="">Overall</td> */}
                </tr>
            </thead>
            <tbody>
                <TableBody />
            </tbody>
        </Table>
    )
}
