import React, { useState } from "react";
import { Col, Card, Row } from "@themesberg/react-bootstrap";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import store from "../../store";
import * as actions from "../../actions"
import * as colorAPI from '../../api/color'
import { TeacherEmail } from "../../api/teacher";
import GetScore from "../components/GetScore";

import StudentRecentTable from "../OverviewModal_components/StudentRecentTable";
import StudentReport from "../OverviewModal_components/StudentReport";
import { BarChartWidget } from "../../components/Widgets";

// Body of Student modal (Overview)
export default function OverviewModalBody(props) {
    const data = props.data
    const [showReport, setShowReport] = useState(false)
    const [report, setReport] = useState({})

    const lang       = useSelector(state => store.getState().recent.req_data.target_lang);
    const class_name = useSelector(state => store.getState().recent.req_data.class_name);

    if (Object.keys(data).length === 0) return (
        <div>Error Occured</div>
    )

    const handlePdfClick = () => {
        let req_data = {
            "teacher_id": TeacherEmail(),
            "class_name": class_name,
            "student_id": data.student_id,
            "target_lang": lang,
        }
        actions.getStudentReport(req_data).then(response=>{
            setReport(response.data)
            setShowReport(true)
        },error=>{
            //handle error here
        })
    }

	return (<span>
        <Row className="mb-3 mx-auto" style={{maxWidth: "1100px"}}>
            {/* Comprehesnion Bar graph */}
            <Col xs={4}>
                <Card>
                    <p className="text-end pt-3 px-3 m-0 font-size-md color-grey900">
                        Avg Book Level: <span className="font-weight-600">{data.avg_booklevel}</span>
                    </p>
                    <BarChartWidget
                        labels={["Vocab", "Listening", "Reading"]}
                        data = {[{
                                    color: "primary",
                                    id: 1,
                                    label: "Avg",
                                    value: [data.comprehension.vocabulary, data.comprehension.listening, data.comprehension.reading]
                                }]}
                        type = "comp"
                        borderBottom = {true}
                    />
                    <div className='text-center'>
                        <div className='d-flex justify-content-center align-items-center'>
                            { data.comprehension.avg !== 0 ? <>
                                <FontAwesomeIcon icon={faCircle} size="1x" className={colorAPI.Comprehension(data.comprehension.avg)} />
                                <span className="ms-2 font-size-xxl color-grey600 font-weight-600"> {data.comprehension.avg} %</span>
                            </>:
                                '--'
                            }
                        </div>
                        <div className='font-size-xxs color-grey800 fw-bold'>Based on the most recent score for each book.</div>
                        <div className="text-center color-grey700 my-2 fw-bold font-size-sm">COMPREHENSION AVERAGE</div>
                    </div>
                </Card>
            </Col>

            <Col xs={5} className="text-center col-grid">
                {/* Point card */}
                <Card className="justify-content-between">
                    <div className="my-auto position-relative">
                        <img src={process.env.PUBLIC_URL + '/images/star.svg'} width="35%" style={{minWidth: "48px"}} alt='star'/>
                        <span className="text-icon-model fw-bold color-grey900" style={{top: "55%", left: "50%", fontSize: "12px"}}>{data.total_score}</span>
                    </div>
                    <p className="color-grey700 font-size-sm fw-bold">TOTAL POINTS</p>
                </Card>
                {/* Time spent card */}
                <Card className="justify-content-between">
                    <div className="m-auto position-relative">
                        <p className="font-size-lg color-grey800 m-0">
                            <span className="font-size-xxl font-weight-600">{data.total_time_spent.hour}</span>h
                            <span className="font-size-xxl font-weight-600"> {data.total_time_spent.min}</span>m
                        </p>
                    </div>
                        <p className="color-grey700 font-size-sm fw-bold">TOTAL TIME</p>
                </Card>
                {/* Speaking card */}
                <Card className="justify-content-between">
                    <div className="m-auto position-relative">
                        <GetScore type='speaking' data={data.speaking_avg} isModalSummary={true}/>
                    </div>
                    <p className="color-grey700 font-size-sm fw-bold">SPEAKING AVG.</p>
                </Card>
                {/* Writing card */}
                <Card className="justify-content-between">
                    <div className="m-auto position-relative">
                        <GetScore type='writing' data={data.writing_avg} isModalSummary={true}/>
                    </div>
                    <p className="color-grey700 font-size-sm fw-bold">WRITING AVG.</p>
                </Card>
            </Col>

            {/* Student report */}
            <Col xs={3}>
                <Card className="text-center h-100 justify-content-between">
                    <div className="m-auto" onClick={handlePdfClick}>
                        <p className="color-blue500 font-weight-600"><img src={process.env.PUBLIC_URL + '/images/download_icon.svg'} className="me-2" alt='download icon' />PDF</p>
                        <img src={process.env.PUBLIC_URL + '/images/student_report.png'} alt="img" height="120px" className="mx-auto"/>
                    </div>
                    <p className="color-grey700 font-size-sm fw-bold">PROGRESS REPORT</p>
                    <StudentReport modal_status={showReport} data={report} student={props.student} onClose={() => setShowReport(false)}/>
                </Card>
            </Col>
        </Row>

        {/* Table */}
        <Card className="p-4 overflow-auto mx-auto" style={{maxWidth: "1100px"}}>
            <StudentRecentTable list={data} goto={props.goto}/>
        </Card>
    </span>
	);
}
