import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { READERS_TYPE, findByPath,getBookType,isLetterBook,csvToArray,getBookReportThumbnail,getBookReportPDF } from "../../api/books"
import {Popover,OverlayTrigger} from 'react-bootstrap';
export default (props) => {
    const getCoverUrl = (path) => {
        return `https://swlb.storyworld.io/` + process.env.REACT_APP_THUMBNAIL_SRV_VERSION + `/8I5RAGGN2U40Q0/Books/dist/${path}/images/thumbnail.jpg`
    }
    const [popoverContent, setPopoverContent] = useState({"title":"","phonics":"","hf_words":"","bookreport_words":""});    
    const popoverTop = (
        <Popover id="popover-basic" style={{width:"250px"}}>
            <Popover.Title as="h3">{popoverContent.title}</Popover.Title>
            <Popover.Content>
                <b>Book Report Words: </b>{popoverContent.bookreport_words}
            </Popover.Content>
        </Popover>
    );
    return (

        <div className="phonics-card">
            <div className="card mb-3" style={{ maxWidth: "450px" }}>
                <div className="row g-0">
                    <div className="col-md-4">
                        <div className="book-cover">
                        <img src={getBookReportThumbnail(props.data.book_path)} className="img-fluid rounded-start" alt="..." />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <div style={{height:"120px"}}>
                            <p>
                                <span className="badge rounded-pill bg-secondary">
                                    {"Book Report"}
                                </span>


                            </p>
                            <span className="card-title">{"Book Report: "+findByPath(props.data.book_path).title["EN"]}</span> <br />

                            <span className="card-text keywords" style={{marginBottom:50}}>Words: {csvToArray(props.data?.bookreport_words,2)}
                            {csvToArray(props.data.bookreport_words, 2).split(",").length < props.data.phonics.split(",").length ?(<>, &nbsp;<OverlayTrigger trigger="focus" placement="top" overlay={popoverTop}>
                                        <a href="#" onClick={(e) => {
                                            setPopoverContent({
                                                "title": findByPath(props.data.book_path).title["EN"],
                                                "phonics": props.data.phonics,
                                                "hf_words": props.data.highfreq_words,                                                
                                                "bookreport_words":props.data.bookreport_words
                                            }); e.preventDefault()
                                        }}>See All</a>
                                    </OverlayTrigger></>):""}                            
                            </span>

                            {/* <p className="card-text highfreq-words"><small className="text-muted">HF: {props.data.highfreq_words}</small></p> */}
                            </div>
                            <div className="col-sm-12 action-rows">
                                <img src={process.env.PUBLIC_URL + '/images/phonics-download.png'} className="display: displayinline" />
                                <a href={getBookReportPDF(props.data.book_path)} target="_blank">Book Report Sheets</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    )
}