import React, { useState } from 'react'
import { Card, Row, Col } from 'react-bootstrap'

import store from '../store'
import * as colorAPI from '../api/color'

import AverageScoreTable from './Student_components/AverageScoreTable';
import { ScatterChartLvl } from '../components/Charts';
import { BarChartWidget } from '../components/Widgets'

// All Student tab
export default function Student(props) {
    // Labels for graphs (x-axis)
    const label_storyworld = [1, 2, 3, 4, 5]
    const label_lexile = ['BR-90', '100-290', '300-440', '450-590', '600-800']
    const label_fp = ['A-C', 'D-J', 'K-L', 'M-N', 'O-S']

    const [bookLvlBar, setBookLvlBar] = useState({storyworld: "filter-selected", lexile: '', fp: ''})
    const [bookLvlScatter, setBookLvlScatter] = useState({storyworld: "filter-selected", lexile: '', fp: ''})

    const [barLabel, setBarLabel] = useState(label_storyworld)
    const [scatterLabel, setScatterLabel] = useState(label_storyworld)

    const handleClick = (bookLvl, chart_type) => {
        if (chart_type === 'bar') {
            if (bookLvl === 'storyworld') {setBookLvlBar({storyworld: "filter-selected", lexile: '', fp: ''}); setBarLabel(label_storyworld)}
            if (bookLvl === 'lexile') {setBookLvlBar({storyworld: "", lexile: 'filter-selected', fp: ''}); setBarLabel(label_lexile)}
            if (bookLvl === 'fp') {setBookLvlBar({storyworld: "", lexile: '', fp: 'filter-selected'}); setBarLabel(label_fp)}
        } else if (chart_type === 'scatter') {
            if (bookLvl === 'storyworld') {setBookLvlScatter({storyworld: "filter-selected", lexile: '', fp: ''}); setScatterLabel(label_storyworld)}
            if (bookLvl === 'lexile') {setBookLvlScatter({storyworld: "", lexile: 'filter-selected', fp: ''}); setScatterLabel(label_lexile)}
            if (bookLvl === 'fp') {setBookLvlScatter({storyworld: "", lexile: '', fp: 'filter-selected'}); setScatterLabel(label_fp)}
        }
    }

    let data = store.getState().recent.data
    let avgBookData = data.avg_booklevel_graph
    const compGraphData = data.comprehension_graph

    let barData = {}
    for (const key in avgBookData) {
        let value = avgBookData[key]["total_students"]
        if (!value) value = 0
        barData[key] = value
    }

    let scatterData = compGraphData.map(d => {
        return {
            label: d.student_name,
            data: [{x: d.avg_book_level, y: d.comprehension_avg?d.comprehension_avg:0}], // to make null 0
            backgroundColor: colorAPI.Comp_graph(d.comprehension_avg),
            pointRadius: 8,
            pointHoverRadius: 8
        }
    });

    return (
        <div>
            <Row className="mb-4">
                {/* Bar graph */}
                <Col>
                    <Card className="p-4 h-100">
                        <Row>
                            <Col>
                                <p className="font-weight-500 font-size-lg color-grey900">Students Per Average Level</p>
                            </Col>
                            <Col className="d-flex justify-content-end align-items-center">
                                    <p className={"px-2 py-1 mx-2 font-size-sm font-weight-600 color-grey900 graph-filter " + bookLvlBar.storyworld} onClick={() => handleClick("storyworld", "bar")} >StoryWorld</p>
                                    <p className={"px-2 py-1 mx-2 font-size-sm font-weight-600 color-grey900 graph-filter " + bookLvlBar.lexile} onClick={() => handleClick("lexile", "bar")} >Lexile</p>
                                    <p className={"px-2 py-1 mx-2 font-size-sm font-weight-600 color-grey900 graph-filter " + bookLvlBar.fp} onClick={() => handleClick("fp", "bar")} > F&P</p>
                            </Col>
                        </Row>
                        <BarChartWidget
                            title="# Student Per Average Level"
                            labels={barLabel}
                            data = {[{
                                color: "primary",
                                id: 1,
                                label: "Avg",
                                value: store.getState().recent.data.avg_booklevel_graph
                            }]}
                        />
                        <Row className="mt-2 text-center">
                            <p className="mb-0 font-weight-500 font-size-sm color-grey800">Average Book Level</p>
                        </Row>
                    </Card>
                </Col>
                {/* Scatterplot graph */}
                <Col>
                    <Card className="p-4">
                        <Row>
                            <Col>
                                <p className="font-weight-500 font-size-lg color-grey900">Class Comprehension</p>
                            </Col>
                            <Col className="d-flex justify-content-end align-items-center">
                                    <p className={"px-2 py-1 mx-2 font-size-sm font-weight-600 color-grey900 graph-filter " + bookLvlScatter.storyworld} onClick={() => handleClick("storyworld", "scatter")} >StoryWorld</p>
                                    <p className={"px-2 py-1 mx-2 font-size-sm font-weight-600 color-grey900 graph-filter " + bookLvlScatter.lexile} onClick={() => handleClick("lexile", "scatter")} >Lexile</p>
                                    <p className={"px-2 py-1 mx-2 font-size-sm font-weight-600 color-grey900 graph-filter " + bookLvlScatter.fp} onClick={() => handleClick("fp", "scatter")} >F&P</p>
                            </Col>
                        </Row>
                        <Card>
                            <ScatterChartLvl chartData={scatterData} labels={scatterLabel}/>
                        </Card>
                        {/* <Row className="mt-3 text-center">
                            <p className="mb-0 font-weight-500 font-size-sm color-grey800">Average Book Level</p>
                        </Row> */}
                    </Card>
                </Col>
            </Row>
            {/* Table */}
            <Card className="overflow-auto px-5 pt-3">
                <AverageScoreTable list={store.getState().recent.data.avg_score} handleClick={props.handleClick} />
            </Card>
        </div>
    )
}
