const standards = [
    { key:'elpa21', title: 'ELPA21', s3url: 'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/ELPA21.pdf'},
    { key:'actfl', title: 'ACTFL', s3url: 'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/ACTFL.pdf'},
    { key:'wida', title:'WIDA', s3url:'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/WIDA.pdf'},
    { key:'ccss', title:'CCSS', s3url:'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/CCSS.pdf'},
    { key:'ca', title:'CA-ELD/CA-CCSS', s3url:'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/CA-ELD.pdf'},
    { key:'ny', title:'NY-ELA', s3url:'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/NY-ELA.pdf'},
    { key:'tx', title:'TexasELPS', s3url:'https://sw-materials-documents.s3.amazonaws.com/documents/standards-and-assessments/Texas-ELPS.pdf'}

]
export { standards };