import React from 'react'

// Navigation bar
export default function Header() {
    return (
        <header>
            <iframe src="https://www.storyworld.io/blank" title="description" style={{width: '100%', height: '55px'}}></iframe>
        </header>
    )
}
