import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Card, Container, Form, Accordion, useAccordionButton } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-regular-svg-icons";

import store from '../../store';
import * as actions from "../../actions"
import * as actiontypes from "../../actions/actiontypes"
import { TeacherEmail } from "../../api/teacher";
import GetScore from "../components/GetScore";
import { client } from "../../api/client";

import { standards } from "../../api/standards";
import { BarChartWidget } from "../../components/Widgets";
import { PieChartTime } from "../../components/Charts";

// Body of Student modal (Book)
export default function BookModalBody(props) {
    let baseData = store.getState().recent
    const [data, setData] = useState(props.data)
    const [speakingResubmit, setSpeakingResubmit] = useState(false)
    const [writingResubmit, setwritingResubmit] = useState(false)
    const [isWordbook, setIsWordbook] = useState('')
    useEffect(() => {
        setData(props.data)
    }, [props])

    const [show, setShow] = useState(false)
    const [cardId, setCardId] = useState('')
    const [recordId, setRecordId] = useState('')
    const [resubmitType, setResubmitType] = useState('')

	const [speakingSubmitted, setSpeakingSubmitted] = useState(false)
	const [writingSubmitted, setWritingSubmitted] = useState(false)
    const [btnClass, setBtnClass] = useState({
        speaking: 'btn-disable',
        writing: 'btn-disable',
    })
    const [errorDiv, setErrorDiv] = useState('d-none')

    // For speaking dropdown
    let speakDropdown = {}, writeDropdown = {}
    try {
        speakDropdown['speak_retell'] = {id: data.speaking.recordings.speak_retell.id, score: data.speaking.recordings.speak_retell.score}
    } catch (error) {
        speakDropdown['speak_retell'] = {id: '', score: ''}
    }
    try {
        speakDropdown['speak_read_aloud'] = {id: data.speaking.recordings.speak_read_aloud.id, score: data.speaking.recordings.speak_read_aloud.score}
    } catch (error) {
        speakDropdown['speak_read_aloud'] = {id: '', score: ''}
    }
    try {
        speakDropdown['speak_depth_of_knowledge']  = {id: data.speaking.recordings.speak_depth_of_knowledge.id, score: data.speaking.recordings.speak_depth_of_knowledge.score}
    } catch (error) {
        speakDropdown['speak_depth_of_knowledge']  = {id: '', score: ''}
    }
    try {
        speakDropdown['average'] = data.speaking.average
    } catch (error) {
        speakDropdown['average'] = {avg: '', state: ''}
    }

    // For writing dropdown
    try {
        writeDropdown['write_recount']    = {id: data.writing.writings.write_recount.id, score: data.writing.writings.write_recount.score}
    } catch (error) {
        writeDropdown['write_recount']    = {id: '', score: ''}
    }
    try {
        writeDropdown['write_inference']  = {id: data.writing.writings.write_inference.id, score: data.writing.writings.write_inference.score}
    } catch (error) {
        writeDropdown['write_inference']  = {id: '', score: ''}
    }
    try {
        writeDropdown['write_opinion']    = {id: data.writing.writings.write_opinion.id, score: data.writing.writings.write_opinion.score}
    } catch (error) {
        writeDropdown['write_opinion']    = {id: '', score: ''}
    }
    try {
        writeDropdown['average'] = data.writing.average
    } catch (error) {
        writeDropdown['average'] = {avg: '', state: ''}
    }

    const [speakingDropdown, setSpeakingDropdown] = useState(speakDropdown)
    const [writingDropdown, setWritingDropdown] = useState(writeDropdown)
    useEffect(() => {
        setSpeakingDropdown(speakDropdown)
        setWritingDropdown(writeDropdown)
        if (data.book_meta && data.book_meta.content === 'word-book') setIsWordbook(true)
        else setIsWordbook(false)
        if (data.speaking) setSpeakingResubmit(data.speaking.submitted)
        if (data.writing) setwritingResubmit(data.writing.submitted)
    }, [data])

    // Standard PDFs for dropdown text
    const standard = localStorage.getItem('standard')
    const standard_dict = {
        'actfl' : {
            1: 'Novice-low',
            2: 'Novice-mid',
            3: 'Novice-high',
            4: 'Intermediate-low',
            5: 'Intermediate-mid',
            6: 'Intermediate-high'
        },
        'wida'  : {
            1: 'Level 1',
            2: 'Level 2',
            3: 'Level 3',
            4: 'Level 4',
            5: 'Level 5',
            6: 'Level 6'
        },
        'ccss'  : {
            1: 'Emerging (Early stage)',
            2: 'Emerging (Exit)',
            3: 'Expanding (Early stage)',
            4: 'Expanding (Exit)',
            5: 'Bridging (Early stage)',
            6: 'Bridging (Exit)'
        },
        'ca'    : {
            1: 'Emerging (Early stage)',
            2: 'Emerging (Exit)',
            3: 'Expanding (Early stage)',
            4: 'Expanding (Exit)',
            5: 'Bridging (Early stage)',
            6: 'Bridging (Exit)'
        },
        'ny'    : {
            1: 'Low',
            2: 'Low-Mid',
            3: 'Mid',
            4: 'Mid-High',
            5: 'High',
            6: 'Excels'
        },
        'tx'    : {
            1: 'Beginning',
            2: 'Intermediate',
            3: 'Advanced',
            4: 'Advanced High',
            5: 'Not Used',
            6: 'Not Used'
        }
    }

    // For question accordian open and close
    const [questionState, setQuestionState] = useState({
        'speak_retell'              : faPlusSquare,
        'speak_read_aloud'          : faPlusSquare,
        'speak_depth_of_knowledge'  : faPlusSquare,
        'write_recount'             : faPlusSquare,
        'write_inference'           : faPlusSquare,
        'write_opinion'             : faPlusSquare
    })

    if (Object.keys(data).length === 0) return <div>null</div>

    if (data.length === 0) return (
        <div>Error Occured</div>
    )

    // Writing answers
	let recountText = baseData.recount, inferenceText = baseData.inference, opinionText = baseData.opinion

	const handleChange = (event) => {
		let speaking = ['speak_retell', 'speak_read_aloud', 'speak_depth_of_knowledge']
		let writing = ['write_recount', 'write_inference', 'write_opinion']
		if (speaking.includes(event.target.id)) {
            setSpeakingSubmitted(false)
            let change = {...speakingDropdown}
            change[event.target.id].score = event.target.value
            setSpeakingDropdown(change)
            setBtnClass({...btnClass, speaking: 'btn-default'})
        }
		if (writing.includes(event.target.id)) {
            setWritingSubmitted(false)
            let change = {...writingDropdown}
            change[event.target.id].score = event.target.value
            setWritingDropdown(change)
            setBtnClass({...btnClass, writing: 'btn-default'})
        }
	}

	const handleResubmit = (event, id, recordId, type) => {
        let dropdown = type === 'speaking'? speakingDropdown: writingDropdown
        let ids = []
        let keys = Object.keys(dropdown)
        keys.forEach(key => {
            if (dropdown[key]['id']) ids.push(dropdown[key]['id'])
        })

        let req_data = {
            "student_id": props.student.id,
            "teacher_id": TeacherEmail(),
            "book_name" : data.assigned_book,
            // "pageNo"    : page_no,
            "type"      : type,
            "ids"       : ids
        }

        actions.setResubmit(req_data).then(resolve=>{
            store.dispatch({type:actiontypes.ALLOW_RESUBMISSION, payload: resolve})
        },error=>{
            //handle error here
        }).then(() => {
            keys.forEach(key => {
                dropdown[key].score = null
            })

            if (type === 'speaking') {
                setSpeakingDropdown({...dropdown, average: store.getState().recent.allow_resubmit.average})
                setSpeakingResubmit(false)
            }
            if (type === 'writing') {
                setWritingDropdown({...dropdown, average: store.getState().recent.allow_resubmit.average})
                setwritingResubmit(false)
            }
        })
		setShow(false)
        // hideCard(event)
	}

	const handleSubmit = (event, type) => {
        if (event.target.classList.contains('btn-disable')) return
        let dropdown_data
        if (type === "speaking") dropdown_data = speakingDropdown
        else if (type === "writing") dropdown_data = writingDropdown
        let keys = Object.keys(dropdown_data)
		let scores = JSON.stringify([
            {
                score: dropdown_data[keys[0]].score,
                id: dropdown_data[keys[0]].id
            },{
                score: dropdown_data[keys[1]].score,
                id: dropdown_data[keys[1]].id
            },{
                score: dropdown_data[keys[2]].score,
                id: dropdown_data[keys[2]].id
            }
        ])

		let req_data = {
            teacher_id: store.getState().recent.req_data.teacher_id,
			scores,
			type
		}

		client.post(process.env.REACT_APP_API_BASE_URL+'/api/send/score', req_data)
			.then(res => {
                if (res.status !== 200) {
                    setErrorDiv('')
                    setTimeout(() => {
                        setErrorDiv('d-none')
                    }, 3000);
                    return
                }

                if (type === 'speaking') {
                    setSpeakingSubmitted(true)
                    setSpeakingResubmit(res.submitted)
                    setSpeakingDropdown({...speakingDropdown, average: res.average})
                }
                if (type === 'writing') {
                    setWritingSubmitted(true)
                    setwritingResubmit(res.submitted)
                    setWritingDropdown({...writingDropdown, average: res.average})
                }
			})
            .catch(err => {
                console.log(err)
                setErrorDiv('')
                setTimeout(() => {
                    setErrorDiv('d-none')
                }, 3000);
                return
            })
	}

    // Custom toggle for opening question accordian
    function CustomToggle({ eventKey, id }) {
        const decoratedOnClick = useAccordionButton(eventKey, () => {
            let state = {}
            if (questionState[id] === faPlusSquare) state[id] = faMinusSquare
            if (questionState[id] === faMinusSquare) state[id] = faPlusSquare
            setQuestionState({...questionState, ...state})
        });
        return (
            <FontAwesomeIcon
                icon={questionState[id]}
                className='me-2 color-grey950 font-size-xl'
                style={{cursor: 'pointer'}}
                onClick={decoratedOnClick}
            />
        );
    }

    const SpeakWriteHeader = ({type, id, index=0}) => {
        let sub_type, dropdown
        if (type === 'speaking') {
            sub_type = 'recordings'
            dropdown = speakingDropdown
        }
        if (type === 'writing') {
            sub_type = 'writings'
            dropdown = writingDropdown
        }
        let title = {
            'speak_retell': 'Retell the Story',
            'speak_read_aloud': 'Read Aloud',
            'speak_depth_of_knowledge': 'Depth of knowledge',
            'write_recount': 'Recount',
            'write_inference': 'Inference',
            'write_opinion': 'Opinion'
        }
        let question
        if (data.game_questions[id] && data.game_questions[id][index]) {
            question = data.game_questions[id][index].qn_text.replaceAll('||', '\n').trim()
        }

        return (
            <Accordion defaultActiveKey={questionState[id]===faMinusSquare?"0":""}>
                <Accordion.Item eventKey="0" className='border-0'>
                    <div className="d-flex align-items-center justify-content-between">
                        <CustomToggle eventKey="0" id={id} />
                        <p className="color-grey800 my-0 font-size-lg font-weight-500">{title[id]}</p>
                        <ScoreDropdown id={id} value={dropdown[id].score} disable={data[type][sub_type][id].id?false:true}/>
                    </div>
                    <Accordion.Body className='py-0 ps-4'>
                        <p className="ps-1 font-weight500 color-grey900 m-0" style={{whiteSpace: 'pre-line'}}>{question}</p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        )
    }

	const ScoreDropdown = ({id, value, disable}) => {
        if (value === null) value = ''
		return <span className='d-flex align-items-center ms-auto'>
            <span className='me-2 color-grey700'>Score: </span>
            <Form.Select className="shortened-select color-grey800" style={{width: '55px'}} size="sm" id={id} onChange={handleChange} value={value} disabled={disable}>
                <option value="">-- {Object.keys(standard_dict).includes(standard)?'No answer':null}</option>
                <option value="1">1 {Object.keys(standard_dict).includes(standard)?standard_dict[standard][1]:null}</option>
                <option value="2">2 {Object.keys(standard_dict).includes(standard)?standard_dict[standard][2]:null}</option>
                <option value="3">3 {Object.keys(standard_dict).includes(standard)?standard_dict[standard][3]:null}</option>
                <option value="4">4 {Object.keys(standard_dict).includes(standard)?standard_dict[standard][4]:null}</option>
                <option value="5">5 {Object.keys(standard_dict).includes(standard)?standard_dict[standard][5]:null}</option>
                <option value="6">6 {Object.keys(standard_dict).includes(standard)?standard_dict[standard][6]:null}</option>
            </Form.Select>
        </span>
	}

    // Open resubmit modal
    const openModal = (type) => {
        setResubmitType(type)
        setShow(true)
    }

	const AllowResubmit = ({type, submitted}) => {
		return <div className="d-flex justify-content-center font-size-sm mb-1 mt-3">
            <a href="#" onClick={() => openModal(type)} id={`resubmit_${type}`}>Allow student to resubmit</a>
		</div>
    }

    const showQuestionCard = (event) => {
        event.currentTarget.getElementsByClassName('card')[0].classList.remove('d-none')
        event.currentTarget.getElementsByClassName('img-noHover')[0].classList.add('d-none')
        event.currentTarget.getElementsByClassName('img-Hover')[0].classList.remove('d-none')
    }
    const hideQuestionCard = (event) => {
        event.currentTarget.getElementsByClassName('card')[0].classList.add('d-none')
        event.currentTarget.getElementsByClassName('img-noHover')[0].classList.remove('d-none')
        event.currentTarget.getElementsByClassName('img-Hover')[0].classList.add('d-none')
    }
    // Question hover for comprehension card
    const QuestionHover = ({name, index=0}) => {
        let question
        if (data.game_questions[name] && data.game_questions[name][index]) {
            question = data.game_questions[name][index].qn_text.replaceAll('||', '\n').trim()
        }
        return <span className="position-relative" onMouseEnter={showQuestionCard} onMouseLeave={hideQuestionCard}>
            <img src={process.env.PUBLIC_URL + '/images/question_mark.svg'} className="me-2 img-noHover" alt='question mark'/>
            <img src={process.env.PUBLIC_URL + '/images/question_mark_hover.svg'} className="me-2 img-Hover d-none" alt='question mark hover'/>
            <Card className="px-4 py-3 card-question d-none">
				<p className="fw-bold color-grey900 font-size-lg text-center">{name === 'speak_read_aloud' ? 'Read Aloud' : 'Question'}:</p>
				<p className="font-weight500 color-grey900" style={{whiteSpace: 'pre-line'}}>{question}</p>
			</Card>
        </span>
    }

    const Notification = () => {
        return (
            <div className={'error-div ' + errorDiv} onClick={(event => {event.target.classList.add('d-none')})}>Error Occured</div>
        )
    }

    const Standards = () => {
        return(
            <>
            <div className="p-2 flex-shrink-0" style={{marginLeft: '2em'}}>
                <ul className="list-unstyled">
                    <li key={0}><strong><u>Standards</u></strong></li>
                    {standards.slice(0,3).map(function (item,index) {
                        return <li key={item.key}>{index+1}. <a href={item.s3url} target="_blank">{item.title}</a></li>;
                    })}
                </ul>
            </div>
            <div className="p-2" >
                <br/>
                <ul className="list-unstyled">
                    {standards.slice(3,6).map(function (item,index) {
                        return <li key={item.key}>{index+4}. <a href={item.s3url} target="_blank">{item.title}</a></li>;
                    })}
                </ul>
            </div>
            </>
        )
    }

	return (
        <Container className="px-3 bg-color">
            <Notification />
            {/* Resubmit answers modal */}
            <Modal
                show={show}
                onHide={()=>setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <p className="fw-bold color-grey900 font-size-lg">Allow the student to resubmit answers?</p>
                    <p className="font-weight500 color-grey900">
                        If you click "Allow resubmission", the new answers submitted by the student will replace the previous answer.
                    </p>
                    <div className="d-flex justify-content-between flex-wrap mb-2">
                        <button className="btn bg-red600 color-white fw-bold" onClick={() => setShow(false)}>Cancel</button>
                        <button className="btn btn-primary fw-bold ms-auto" id={cardId} onClick={(event) => handleResubmit(event, cardId, recordId, resubmitType)}>Allow resubmission</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Book details */}
            <div className='d-flex justify-content-between'>
                <Button size="md" className="bg-transparent color-blue500 btn-back" onClick={props.back} ><FontAwesomeIcon icon={faArrowLeft} /> All Books</Button>
                <div className="text-center" style={{width: "225px"}}>
                    <span>Most recent activity: </span><span>{data.date_completed}</span>
                </div>
            </div>
            <div className="mt-3 mb-4 d-flex">
                {data.assigned_book?<img src={actions.getCoverUrl(data.assigned_book)} alt="img" height="150px" className="me-2"/>:null}
                <div className="ms-3">
                    <h5 className="mb-0 font-weight-600 font-size-xxxl">{data.book_meta.title}</h5>
                    <p className="mb-2 font-size-sm color-grey900">Level {data.book_meta.book_level} &emsp;|&emsp; Lexile: {data.book_meta.lexile} &emsp;|&emsp;	F&P: {data.book_meta.fp}</p>
                    <div className='d-flex align-items-center'>
                        <div className='d-flex me-4'>
                            <span className="d-inline-block color-grey900" style={{width: "100px"}}>Total Time: </span>
                            <span>{data.total_time_spent}</span>
                        </div>
                        <div className='me-4' style={{width: '90px'}}>
                            <PieChartTime charData={[data.time_spent.time_spent_book, data.time_spent.time_spent_quiz]} />
                        </div>
                        <div className="text-center position-relative">
                            <img src={process.env.PUBLIC_URL + '/images/star.svg'} className="mx-auto my-2" width="70px" alt='star'/>
                            <span className="center-no-icon fw-bold font-size-lg color-grey900" >{data.point}</span>
                        </div>
                    </div>
                </div>

                <Standards />
                {/* Overall Grade */}
                {/* <Row className="ms-auto">
                    <Col>
                        <Card style={{width: "202px"}}>
                            <Card.Header className="bg-grey-modelHeader font-size-xl color-grey900 font-weight-600 text-center">OVERALL GRADE</Card.Header>
                            <Card.Body className="d-flex">
                                <Form.Select className="w-auto m-auto color-grey800 font-weight-600 font-size-xxl my-2" size="md" value={overall} onChange={handleOverallChange}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </Form.Select>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> */}

                {/* Points Card */}
                {/* <Card className="ms-2" style={{width: "192px"}}>
                    <Card.Header className="bg-grey-modelHeader color-grey800 fw-bold text-center">POINTS</Card.Header>
                    <Card.Body className="text-center position-relative">
                        <img src={process.env.PUBLIC_URL + '/images/star.svg'} className="mx-auto my-2" width="70px"/>
                        <span className="text-icon-model fw-bold font-size-lg color-blue800" >{new random("num", 1000, 100).data}</span>
                    </Card.Body>
                </Card> */}
            </div>

            <Row className="my-4 justify-content-between">
                {/* Comprehension Card */}
                <div className="col-4">
                    <Card className="mx-auto book-modal-card">
                        <Card.Header className="bg-grey950">
                            <Row className='ps-2 py-1'>
                                <span className="font-size-xl font-weight-600 color-white w-auto">COMPREHENSION</span>
                                <div className="badge rounded-pill bg-white color-grey800 fs-6 ms-auto me-2 d-flex align-items-center" style={{width: "fit-content", height: "32px"}}>
                                    <span className="font-weight-500 me-2">Recent:</span>
                                    <span className="align-baseline" style={{fontWeight: "400"}}>
                                        {/* <FontAwesomeIcon icon={faCircle} size="xs" className={"ms-3 me-2 "+colorAPI.Comprehension(data.comprehesion.last_comprehension)} style={{width: "8px"}}/>{data.comprehesion.last_comprehension}% */}
                                        <GetScore type='comprehension' data={data.comprehesion.last_comprehension} comp_size={'1x'}/>
                                    </span>
                                </div>
                            </Row>
                        </Card.Header>
                        <Card.Body style={{height: '681px'}}>
                            {/* Comprehension most recent bar graph */}
                            <div className="mb-3">
                                <p className="mb-0 color-grey800 font-size-lg font-weight-500 d-flex align-items-center">
                                    <span className='me-2'>Most recent results: </span>
                                    <GetScore type='comprehension' data={data.comprehesion.last_comprehension}/>
                                    <span className="color-grey700 font-size-sm ms-auto">{data.comprehesion.last_opened}</span>
                                </p>
                                <p className='color-grey800 fw-bold' style={{fontSize: '10px'}}>
                                    Combines Vocabulary, Listening & Reading quiz results.
                                </p>
                                <BarChartWidget
                                    labels={isWordbook ? ["Vocab"] : ["Vocab", "Listening", "Reading"]}
                                    data = {[{
                                                color: "primary",
                                                id: 1,
                                                label: "Avg",
                                                value: isWordbook ? [data.comprehesion.last_session.vocabulary] : [data.comprehesion.last_session.vocabulary, data.comprehesion.last_session.listen, data.comprehesion.last_session.reading]
                                            }]}
                                    type = "comp"
                                />
                            </div>
                            <hr className='mt-0'/>

                            {/* Comprehension most recent bar graph */}
                            <div className="mb-3">
                                <p className="color-grey800 font-size-lg font-weight-500 d-flex align-items-center">
                                    <span className='me-2'>First results: </span>
                                    <GetScore type='comprehension' data={data.comprehesion.first_comprehension}/>
                                    <span className="color-grey700 font-size-sm ms-auto">{data.comprehesion.first_opened}</span>
                                </p>
                                <BarChartWidget
                                    labels={isWordbook ? ["Vocab"] : ["Vocab", "Listening", "Reading"]}
                                    data = {[{
                                                color: "primary",
                                                id: 1,
                                                label: "Avg",
                                                value: isWordbook ? [data.comprehesion.first_session.vocabulary] : [data.comprehesion.first_session.vocabulary, data.comprehesion.first_session.listen, data.comprehesion.first_session.reading]
                                            }]}
                                    type = "comp"
                                    isSingleSession = {data.is_single_session}
                                />
                            </div>
                            <hr className='mt-0 mb-1'/>
                            <hr className='mt-0'/>

                            {/* Multiple choice question */}
                            <div>
                                <p className="mb-0 color-grey800 font-size-lg font-weight-500 d-flex align-items-center">Multiple Choice Questions</p>
                                <p className='color-grey800 fw-bold' style={{fontSize: '10px'}}>
                                    Click (?) to view each multiple choice questios
                                </p>
                                <Row className="px-3">
                                    {
                                        isWordbook?
                                        <>
                                            <Col className='d-flex flex-column p-0 mb-1'>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_vocab' index='0'/>Vocab 1</span>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_vocab' index='1'/>Vocab 2</span>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_vocab' index='2'/>Vocab 3</span>
                                            </Col>
                                            <Col className='d-flex flex-column p-0 mb-1'>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_vocab' index='3'/>Vocab 4</span>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_vocab' index='4'/>Vocab 5</span>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_vocab' index='5'/>Vocab 6</span>
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col xl={4} lg={6} className='d-flex flex-column p-0 mb-1'>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_vocab' index='0'/>Vocab 1</span>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_vocab' index='1'/>Vocab 2</span>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_vocab' index='2'/>Vocab 3</span>
                                            </Col>
                                            <Col xl={4} lg={6} className='d-flex flex-column p-0 mb-1'>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_listen' index='0'/>Listen 1</span>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_listen' index='1'/>Listen 2</span>
                                            </Col>
                                            <Col xl={4} lg={6} className='d-flex flex-column p-0 mb-1'>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_read' index='0'/>Read 1</span>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_read' index='1'/>Read 2</span>
                                                <span className="mb-1 color-grey800 font-weight-500"><QuestionHover name='comp_read' index='2'/>Read 3</span>
                                            </Col>
                                        </>
                                    }
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                {/* Speaking Card */}
                <div className="col-4">
                    <Card className="mx-auto book-modal-card h-100">
                        {/* If wordbook show full grey */}
                        {
                        isWordbook?
                        <>
                        <Card.Header className="bg-grey950">
                            <Row className='ps-2 py-1'>
                                <span className="font-size-xl font-weight-600 color-white w-auto">SPEAKING</span>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <div className="mt-3 color-grey700 text-center font-size-lg">
                                Word books do not have <br/> SPEAKING quizzes
                            </div>
                        </Card.Body>
                        </>
                        :
                        <>
                        <Card.Header className="bg-grey950">
                            <Row className='ps-2 py-1'>
                                <span className="font-size-xl font-weight-600 color-white w-auto">SPEAKING</span>
                                <div className="badge rounded-pill bg-white color-grey800 fs-6 ms-auto me-2 d-flex align-items-center" style={{width: "fit-content", height: "32px"}}>
                                    <span className="font-weight-500 me-2">Average:</span>
                                    <GetScore type='speaking' data={speakingDropdown['average']} isBookmodal={true}/>
                                </div>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {/* Retell the Story */}
                            <div style={{minHeight: "190px"}}>
                                <SpeakWriteHeader type='speaking' id='speak_retell' />
                                <audio className="audio-tag" controls controlsList="nodownload" src={actions.getS3Audio(data.speaking.recordings.speak_retell.audio)}>
                                </audio>
                                {
                                    data.speaking.recordings.speak_retell.id?
                                    <div className='text-end mb-2 me-2'>
                                        <span className="color-grey800 font-size-sm text-end">Submitted: {data.speaking.recordings.speak_retell.date}</span>
                                    </div>:
                                    <div className="d-flex justify-content-center align-item-center">
                                        <span className="color-grey500">Not submitted</span>
                                    </div>
                                }
                            </div>

                            {/* Read Aloud */}
                            <div style={{minHeight: "190px"}}>
                                <SpeakWriteHeader type='speaking' id='speak_read_aloud' />
                                <audio className="audio-tag" controls controlsList="nodownload" src={actions.getS3Audio(data.speaking.recordings.speak_read_aloud.audio)}>
                                </audio>
                                {
                                    data.speaking.recordings.speak_read_aloud.id?
                                    <div className='text-end mb-2 me-2'>
                                        <span className="color-grey800 font-size-sm text-end">Submitted: {data.speaking.recordings.speak_read_aloud.date}</span>
                                    </div>:
                                    <div className="d-flex justify-content-center align-item-center">
                                        <span className="color-grey500">Not submitted</span>
                                    </div>
                                }
                            </div>

                            {/* Depth of knowledge */}
                            <div style={{minHeight: "190px"}}>
                                <SpeakWriteHeader type='speaking' id='speak_depth_of_knowledge' />
                                <audio className="audio-tag" controls controlsList="nodownload" src={actions.getS3Audio(data.speaking.recordings.speak_depth_of_knowledge.audio)}>
                                </audio>
                                {
                                    data.speaking.recordings.speak_depth_of_knowledge.id?
                                    <div className='text-end mb-2 me-2'>
                                        <span className="color-grey800 font-size-sm text-end">Submitted: {data.speaking.recordings.speak_depth_of_knowledge.date}</span>
                                    </div>:
                                    <div className="d-flex justify-content-center align-item-center">
                                        <span className="color-grey500">Not submitted</span>
                                    </div>
                                }
                            </div>

                            {/* Submit button and resubmit link */}
                            <div className="text-center">
                                {
                                    speakingSubmitted?
                                        <button className={"btn btn-submit-score btn-submitted"}>Submitted</button>
                                    :
                                        <button className={"btn btn-submit-score "+btnClass.speaking} onClick={(event) => handleSubmit(event, "speaking")}>Submit Scores</button>
                                }
                            </div>
                            <AllowResubmit type='speaking' submitted={speakingResubmit}/>
                        </Card.Body>
                        </>
                        }
                    </Card>
                </div>

                {/* Writing Card */}
                <div className="col-4">
                    <Card className="ms-auto book-modal-card h-100">
                        {/* If wordbook show full grey */}
                        {
                        isWordbook?
                        <>
                        <Card.Header className="bg-grey950">
                            <Row className='ps-2 py-1'>
                                <span className="font-size-xl font-weight-600 color-white w-auto">WRITING</span>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <div className="mt-3 color-grey700 text-center font-size-lg">
                                Word books do not have <br/> WRITING quizzes
                            </div>
                        </Card.Body>
                        </>
                        :<>
                        <Card.Header className="bg-grey950 fw-bold text-center">
                            <Row className='ps-2 py-1'>
                                <span className="font-size-xl font-weight-600 color-white w-auto">WRITING</span>
                                <div className="badge rounded-pill bg-white color-grey800 fs-6 ms-auto me-2 d-flex align-items-center" style={{width: "fit-content", height: "32px"}}>
                                    <span className="font-weight-500 me-2">Average:</span>
                                    <GetScore type='writing' data={writingDropdown['average']} isBookmodal={true}/>
                                </div>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {/* Recount */}
                            <div style={{minHeight: "190px"}}>
                                <SpeakWriteHeader type='writing' id='write_recount' />
                                {
                                    data.writing.writings.write_recount.id?
                                    <>
                                        <Card className="bg-color color-grey950 px-3 py-2 mt-2 overflow-auto border-0 custom-scroll" style={{height: "90px"}}>
                                            {recountText}
                                        </Card>
                                        <div className='text-end mb-2 me-2'>
                                            <span className="color-grey800 font-size-sm text-end">Submitted: {data.writing.writings.write_recount.date}</span>
                                        </div>
                                    </>:
                                    <Card className="bg-color color-grey950 px-3 py-2 mt-2 overflow-auto border-0 custom-scroll" style={{height: "90px"}}>
                                        <span className="color-grey500 text-center font-weight-600 my-auto">Not submitted</span>
                                    </Card>
                                }
                            </div>

                            {/* Inference */}
                            <div style={{minHeight: "190px"}}>
                                <SpeakWriteHeader type='writing' id='write_inference' />
                                {
                                    data.writing.writings.write_inference.id?
                                    <>
                                        <Card className="bg-color color-grey950 px-3 py-2 mt-2 overflow-auto border-0 custom-scroll" style={{height: "90px"}}>
                                            {inferenceText}
                                        </Card>
                                        <div className='text-end mb-2 me-2'>
                                            <span className="color-grey800 font-size-sm text-end">Submitted: {data.writing.writings.write_inference.date}</span>
                                        </div>
                                    </>:
                                    <Card className="bg-color color-grey950 px-3 py-2 mt-2 overflow-auto border-0 custom-scroll" style={{height: "90px"}}>
                                        <span className="color-grey500 text-center font-weight-600 my-auto">Not submitted</span>
                                    </Card>
                                }
                            </div>

                            {/* Opinion */}
                            <div style={{minHeight: "190px"}}>
                                <SpeakWriteHeader type='writing' id='write_opinion' />
                                {
                                    data.writing.writings.write_opinion.id?
                                    <>
                                        <Card className="bg-color color-grey950 px-3 py-2 mt-2 overflow-auto border-0 custom-scroll" style={{height: "90px"}}>
                                            {/* <span className="color-grey500 text-center font-weight-600">Not submitted</span> */}
                                            {opinionText}
                                        </Card>
                                        <div className='text-end mb-2 me-2'>
                                            <span className="color-grey800 font-size-sm text-end">Submitted: {data.writing.writings.write_opinion.date}</span>
                                        </div>
                                    </>:
                                    <Card className="bg-color color-grey950 px-3 py-2 mt-2 overflow-auto border-0 custom-scroll" style={{height: "90px"}}>
                                        <span className="color-grey500 text-center font-weight-600 my-auto">Not submitted</span>
                                    </Card>
                                }
                            </div>

                            {/* Submit button and resubmit link */}
                            <div className="text-center">
                                {
                                    writingSubmitted?
                                        <button className={"btn btn-submit-score btn-submitted"}>Submitted</button>
                                    :
                                        <button className={"btn btn-submit-score "+btnClass.writing} onClick={(event) => handleSubmit(event, "writing")}>Submit Scores</button>
                                }
                            </div>
                            <AllowResubmit type='writing' submitted={writingResubmit}/>
                        </Card.Body>
                        </>
                    }
                    </Card>
                </div>
            </Row>

            {/* Word Clicked Card */}
            <Card>
                <Card.Header className="bg-grey950 color-white fw-bold text-center">
                    <Row>
                        <Col className='font-size-xl font-weight-600'><span> WORDS CLICKED</span></Col>
                    </Row>
                </Card.Header>
                <Card.Body className="text-start px-7" style={{minHeight: "60px"}}>
                    <div>
                        {
                            data.words_clicked.words.map((word, index) => {
                                return <span key={index} className="word-clicked">{word}</span>
                            })
                        }
                    </div>
                </Card.Body>
            </Card>
        </Container>
	);
}
