export const INCREMENT = "Increment"
export const DECREMENT = "Decrement"

//Recent Activity
export const RA_DEFAULT_LOAD = "Recent Activity Default List"
export const RA_MODAL_PROFILE = "Recent Activity Modal Profile"
export const RA_READ_TEXT_RESPONSE = "Modal reading text response from s3"



//Assigned Books
export const AB_DEFAULT_LOAD = "Assigned Books Default List"

//Classes TAB
export const CLASSES_BARCHART_LOAD = "Bar chart loader"
export const CLASSES_TABULAR_LIST = "Tabular List"

export const DASHBOARD_LOAD = "Dashboard Load"

export const LIBRARY_LOAD = "LibararyLoad"

// Modal book
export const RA_MODAL_BOOK = 'Modal book'
export const RA_MODAL_OVERVIEW = 'Modal overview'

export const ALLOW_RESUBMISSION = 'resubmission'
export const SUBMIT_OVERALL = 'set overall'

export const SET_REQ_DATA = 'set req data'

export const GET_ASSIGNED_BOOK = 'get_assigned_book'

export const SET_RECENT_DATA = 'set_recent_data'

export const SET_LOADING = 'set_loading'

export const SET_PHONICS_SEARCH_QUERY = 'PHONICS SEARCH QUERY'
export const GET_PHONICS_SEARCH_RESULT = 'PHONICS GET SEARCH RESULT'