import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { Card, Col, FormControl, Row, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import store from '../store'
import * as colorAPI from '../api/color'
import * as actions from "../actions/"
import * as actiontypes from "../actions/actiontypes"
import { dataLoading } from '../reducers/recentActivity'

import BookPage from './AssignedBook_components/BookPage'
import GetScore from './components/GetScore'

// Book read tab
const AssignedBooks = forwardRef((props, ref) => {
    const [bookFilter, setBookFilter] = useState('all')
    let match = null

    let responseCopy = store.getState().recent.data.assigned_books
    const [response, setResponse] = useState({})        // Table data

    const [bookName, setBookName] = useState('')

    const [search, setSearch] = useState('')
    const [filterBy, setFilterBy] = useState('book_name')   // Radio button control
    const [clearBtn, setClearBtn] = useState('d-none')
    const [errorDiv, setErrorDiv] = useState('d-none')

    useEffect(() => {
        setResponse(responseCopy)
    }, [responseCopy])

    useImperativeHandle(ref, () => ({
        closeAssignedBookDetail() {
            setBookName('')
        }
    }));

    useEffect(() => {
        // Show clear button('X') if something is typed
        if (search) setClearBtn('')
        else  setClearBtn('d-none')
        if (filterBy==='book_name') {
            setResponse({
                ...responseCopy,
                book_list: responseCopy.book_list.filter(data => data.name.toLowerCase().includes(search.toLowerCase()))
            })
        }
        if (filterBy==='student_name') {
            setResponse({
                ...responseCopy,
                assignments: responseCopy.assignments.filter(data => {
                    if (Object.keys(data.student).length>0) data.student.name.toLowerCase().includes(search.toLowerCase())
                })
            })
        }
    }, [search])

    useEffect(() => {
        if (document.getElementById('searchBox')) document.getElementById('searchBox').focus()

        // Hide right and left circle arrow when reached at end
        let table = document.getElementById('assigned-table')
        let scroll = document.getElementById('assigned-table-scroll')
        if (table) {
            if (table.offsetWidth < scroll.offsetWidth) {
                document.getElementsByClassName('scroll-btn')[0].classList.add('invisible')
                document.getElementsByClassName('scroll-btn')[1].classList.add('invisible')
            } else {
                document.getElementsByClassName('scroll-btn')[0].classList.remove('invisible')
                document.getElementsByClassName('scroll-btn')[1].classList.remove('invisible')
            }
        }
    }, [response])

    const handleChange = (event) => {
        setSearch(event.target.value)
    }

    const handleScroll = () => {
        let scroll_div = document.getElementById('assigned-table-scroll')
        let maxScroll = scroll_div.scrollWidth - scroll_div.clientWidth
        let scrollPosition = scroll_div.scrollLeft / maxScroll // 0 if scroll on left side, 1 if scroll on right side

        if (scrollPosition === 0) {
            document.getElementById('btn_leftScroll').classList.add('disable')
            document.getElementById('btn_rightScroll').classList.remove('disable')
        } else if  (scrollPosition === 1) {
            document.getElementById('btn_leftScroll').classList.remove('disable')
            document.getElementById('btn_rightScroll').classList.add('disable')
        } else {
            document.getElementById('btn_leftScroll').classList.remove('disable')
            document.getElementById('btn_rightScroll').classList.remove('disable')
        }
    }

    const scroll_page = (scroll) => () => {
        let scroll_div = document.getElementById('assigned-table-scroll')
        scroll_div.scrollBy({left: scroll, behavior: 'smooth'});
    }

    const handleBookClick = (event) => {
        let book_name = event.currentTarget.getAttribute('book_name')
        if (book_name) {
            let req_data = {
                teacher_id    : store.getState().recent.req_data.teacher_id,
                class_name    : store.getState().recent.req_data.class_name,
                book_name     : book_name,
                target_lang   : store.getState().recent.req_data.target_lang
            }
            store.dispatch(dataLoading(true))
            actions.getAssignedBook(req_data).then(response=>{
                if (response.status === 200) {
                    store.dispatch({type:actiontypes.GET_ASSIGNED_BOOK,payload: response.data});
                    setBookName(book_name)
                } else {
                    setErrorDiv('')
                    setTimeout(() => {
                        setErrorDiv('d-none')
                    }, 3000);
                }
            }).catch(error=>{
                //handle error here
            }).finally(() => {
                store.dispatch(dataLoading(false))
            })
        }
    }

    const getBookList = () => {
        if (bookFilter === 'all') return Array.isArray(response.all_book_list)?response.all_book_list:[]
        if (bookFilter === 'assigned') return Array.isArray(response.book_list)?response.book_list:[]
        return []
    }

    // Table header which contains the thumbnail of books
    const BookHeader = () => {
        if(Object.keys(response).length !== 0) {
            let bookList = getBookList()
            if (!bookList.length) {
                return <td className="border-0">
                    <div className="noBook-text color-grey700 col-6">
                        {
                            bookFilter==='assigned'?
                                'You have not assigned a book yet. To assign a book, go to the Library and click the edit icon in the top right corner of the book you wish to assign.'
                            :
                                <>It’s time for students to start reading!<br/>
                                (No student has opened a book yet or completed any quizzes.)</>
                            }
                    </div>
                </td>
            }

            return bookList.map((item,index)=>{
                return <td className="px-1" key={index} style={{minWidth: "104px"}} book_name={item.path} onClick={(event) => handleBookClick(event)}>
                    <div className="image-div">
                        {item.path?<img src={actions.getCoverUrl(item.path)} alt="img" width="90px"/>:null}
                        <div className="image-hover">
                            <div className='image-hover-top'></div>
                            <div className='image-hover-bottom'>
                                <span className="fw-bold hover-text">{item.name}</span>
                            </div>
                        </div>
                        <span className="image-hover-bottom-class font-weight-500 font-size-xs">
                            Class Data
                            <img src={process.env.PUBLIC_URL + '/images/arrow_right.svg'} className='ms-1' alt='right arrow' />
                        </span>
                    </div>
                </td>
            })
        } else return null;
    };

    const SpeaKWriteScore = ({data}) => {
        let tooltip = {}
        if (data.speaking.state === "SubmittedNotGraded" || data.writing.state === "SubmittedNotGraded") {
            tooltip = {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "top",
                "title": "Click to assess student work."
            }
        }
        return <div className='d-flex justify-content-evenly mx-2' {...tooltip}>
            <span className='position-relative'>
                <GetScore type='speaking' data={data.speaking} />
            </span>
            <span className='position-relative'>
                <GetScore type='writing' data={data.writing} />
            </span>
        </div>
    }

    const TableScore = ({data, index, student}) => {
        let borderColor
        if (data.status==='NotOpened') {
            borderColor = {color: '#EDF1F8', bgClass: ''}   //Not opened
            return <td key={index}
                className="border-end-0 p-0 text-center assigned-box"
                style={{borderTop: `8px solid ${borderColor.color}`}}>
            </td>
        }

        borderColor = colorAPI.Assigned_Comp(data.comprehesnion, false)            //Opened and played
        return <td key={index}
            className={"border-end-0 p-0 text-center assigned-box "+borderColor.bgClass}
            style={{borderTop: `8px solid ${borderColor.color}`, cursor: 'pointer'}}
            onClick={() => props.handleClick(student, data.path)}
        >
            <SpeaKWriteScore data={data} />
        </td>
    }

    // Table body
    const AssignedList = () =>{
        if(Object.keys(response).length !== 0) {
            return response.assignments.map((assignment,index)=>{
                match = null
                if (!assignment.student.id) return null
                return (
                    <tr key={index} className="color-grey900 assigned-row">
                        <td className="text-start fw-bold text-start border-start-0 hover-modal" onClick={() => props.handleClick({id: assignment.student.id, name: assignment.student.name})}>{assignment.student.name}</td>
                        {
                            getBookList().map((header_book, book_index) => {
                                match = assignment.books.find(item => item.path===header_book.path)
                                if(match) {
                                    return <TableScore data={match} index={book_index} student={assignment.student} key={book_index}/>
                                }
                                return null
                            })
                        }
                    </tr>
                )
            })
        } else return null;
    }

    const ScoreIndicator = () => {
        return (
            <div style={{maxWidth: '500px'}}>
                {/* Comprehension  */}
                <div className='d-inline-flex align-items-center font-size-sm color-grey700'>
                    <div className='me-2 color-grey800'>Comprehension:</div>
                    <span className='me-2'>
                        <img src={process.env.PUBLIC_URL + '/images/comprehension_green.png'} width='30px' className='me-1' alt='comprehension icon'/>
                        <span>76-100%</span>
                    </span>
                    <span className='me-2'>
                        <img src={process.env.PUBLIC_URL + '/images/comprehension_yellow.png'} width='30px' className='me-1' alt='comprehension icon'/>
                        <span>51-75%</span>
                    </span>
                    <span className='me-2'>
                        <img src={process.env.PUBLIC_URL + '/images/comprehension_red.png'} width='30px' className='me-1' alt='comprehension icon'/>
                        <span>0-50%</span>
                    </span>
                    <span className=''>
                        <img src={process.env.PUBLIC_URL + '/images/comprehension_grey.png'} width='30px' className='me-2' alt='comprehension icon'/>
                        <span>No quiz data</span>
                    </span>
                </div>

                {/* Speak & Write */}
                <div className='d-inline-flex align-items-center font-size-sm color-grey700'>
                    <div className='me-2 color-grey800'>Speak & Write:</div>
                    <span className='me-1'>
                        <img src={process.env.PUBLIC_URL + '/images/speaking_noNumber_icon.svg'} width='20px' className='me-1' alt='speaking icon'/>
                        <img src={process.env.PUBLIC_URL + '/images/writing_noNumber_icon.svg'} width='20px' className='me-1' alt='writing icon'/>
                        Needs scoring (click to score) |
                    </span>
                    <span>
                        <span className='position-relative me-1' style={{bottom: '1px'}}>
                            <img src={process.env.PUBLIC_URL + '/images/speaking_number_icon.svg'} width='20px' alt='speaking icon'/>
                            <span className="text-icon-speaking color-blue500 fw-bold" style={{top: '0px'}}>3</span>
                        </span>
                        <span className='position-relative me-1' style={{bottom: '1px'}}>
                            <img src={process.env.PUBLIC_URL + '/images/writing_number_icon.svg'} width='16px' alt='writing icon'/>
                            <span className="text-icon-writing color-blue500 fw-bold">2</span>
                        </span>
                        Score displayed
                    </span>
                </div>
            </div>
        )
    }

    const SearchBar = () =>{
        const hoverEffect = (id, add=false) => {
            if (add) document.getElementById(id).getElementsByClassName('search-dropdown')[0].classList.add('search-dropdown-hover')
            else document.getElementById(id).getElementsByClassName('search-dropdown')[0].classList.remove('search-dropdown-hover')
        }
        return (
            <Row className='p-0'>
                <Col xs={5} className='p-1 position-relative d-flex align-items-center' id="assigned-search">
                    <img src={process.env.PUBLIC_URL + '/images/search_icon.svg'} className="search-icon" alt='search icon' />
                    <FormControl
                        placeholder={filterBy === 'book_name'? "Search books..." : "Search student...  "}
                        aria-label="Username"
                        className="bg-color search-box color-grey700"
                        aria-describedby="basic-addon1"
                        id="searchBox"
                        style={{height: "48px"}}
                        onChange={handleChange}
                        value = {search}
                        onMouseOver={() => hoverEffect('assigned-search', true)}
                        onMouseLeave={() => hoverEffect('assigned-search')}
                    />
                    <span className={`${clearBtn} clear-search color-grey800`} onClick={()=> setSearch('')}>
                        <FontAwesomeIcon icon={faTimes} size={'1x'}/>
                    </span>
                    <select className="form-select-sm search-dropdown color-blue500 font-weight-600" value={filterBy} onChange={(event) => setFilterBy(event.target.value)}>
                        <option value='book_name'>Books</option>
                        <option value='student_name'>Student</option>
                    </select>
                </Col>
                <Col>
                    <div className='d-flex justify-content-between align-items-center'>
                        <ScoreIndicator />
                        <Row className="flex-nowrap justify-content-end align-items-center">
                            {/* <div className="text-start w-auto me-5">
                                <a className="font-weight-500">Manage Assigned Books</a>
                            </div> */}
                            <div className="text-start w-auto px-0">
                                <span id='btn_leftScroll' className="scroll-btn mx-2 rotate-180 disable" onClick={scroll_page(-800)} style={{left: "0", right: "unset"}}></span>
                                <span id='btn_rightScroll' className="scroll-btn mx-2" onClick={scroll_page(800)}></span>
                            </div>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }

    // Radio buttons to filter books
    const ScoreFilter = () => {
        return <>
            <p className="color-grey800 text-start mt-3">Display:</p>
            <div className="form-check text-start d-flex align-items-center">
                <input
                    id="radio_assigned"
                    className="check-btn"
                    type="radio"
                    name="bookFilter"
                    // checked={checkAssigned}              // for checkbox
                    // onChange={(event) => setCheckAssigned(event.target.checked)}
                    checked={bookFilter==='assigned' ? true : false}
                    onChange={(event) => event.target.checked?setBookFilter('assigned'):null}
                />
                <label className="form-check-label font-weight-600 font-size-sm" htmlFor="radio_assigned">
                    Assigned books only
                </label>
            </div>
            <div className="form-check text-start d-flex align-items-center mb-3">
                <input
                    id="radio_all"
                    className="check-btn"
                    type="radio"
                    name="bookFilter"
                    // checked={checkAllBooks}          // for checkbox
                    // onChange={(event) => setCheckAllBooks(event.target.checked)}
                    checked={bookFilter==='all' ? true : false}
                    onChange={(event) => event.target.checked?setBookFilter('all'):null}
                />
                <label className="form-check-label font-weight-600 font-size-sm" htmlFor="radio_all">
                    All books read
                </label>
            </div>
        </>
    }

    const Notification = () => {
        return (
            <div className={'error-div ' + errorDiv} onClick={(event => {event.target.classList.add('d-none')})}>Error Occured</div>
        )
    }

    return (
        <div>
            <Notification />
            {bookName?
                <BookPage goBack={() => setBookName('')} lang={props.lang} handleClick={props.handleClick}/>
            :
                <Card className="px-3 pt-0 pb-5" id="card" style={{overflowX: 'auto'}}>
                    <Row className="align-items-center ms-3 my-4">
                        <SearchBar/>
                    </Row>
                    <div className="table-responsive" id="assigned-table-scroll" onScroll={handleScroll}>
                        <Table className="text-center" id="assigned-table" style={{width: "min-content"}}>
                            <thead className="curved-top">
                                <tr>
                                    <td className="align-top color-grey800 font-size-md col-3 border-0 position-relative" style={{minWidth: "296px"}}>
                                        <ScoreFilter/>
                                    </td>
                                    <BookHeader />
                                </tr>
                            </thead>
                            <tbody>
                                <AssignedList />
                            </tbody>
                        </Table>
                    </div>
                </Card>
            }
        </div>
    )
})

export default AssignedBooks