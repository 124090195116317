import React, { useEffect, useState } from 'react'
import { FormControl, Row, Col } from '@themesberg/react-bootstrap';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';

import * as actions from "../../actions"
import GetScore from '../components/GetScore';

// Table component of Overview modal
export default function StudentRecentTable(props) {
    const listOriginal = props.list.activities
    const [listPagination, setListPagination] = useState([])
    const [list, setList] = useState([])
    const [paginationData, setPaginationData] = useState({
        perPage: parseInt(process.env.REACT_APP_DATA_LIMIT),
        offset: 0,
        currentPage: 0
    })
    const pageCount =  Math.ceil(listPagination.length / paginationData.perPage)

    const [search, setSearch] = useState('')
    const [clearBtn, setClearBtn] = useState('d-none')

    const updateData = () => {
        setList(listPagination.slice(paginationData.offset, paginationData.offset + paginationData.perPage))
    }

    useEffect(() => {
        setListPagination(listOriginal)
        updateData()
    }, [listOriginal])

    useEffect(() => {
        updateData()
    }, [listPagination])

    useEffect(() => {
        updateData()
    }, [paginationData])

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * paginationData.perPage;

        setPaginationData({
            ...paginationData,
            currentPage: selectedPage,
            offset: offset
        })
    };

    useEffect(() => {
        // Show clear button('X') if something is typed
        search ? setClearBtn('') : setClearBtn('d-none')

        setListPagination(listOriginal.filter(data => data['book_name'].toLowerCase().includes(search.toLowerCase())))
        setPaginationData({         // Set page to 1 when search
            ...paginationData,
            offset: 0,
            currentPage: 0
        })
    }, [search])

    const handleChange = (event) => {
        setSearch(event.target.value)
    }

    const TableBody = () => {
        if (list.length === 0) return <tr><td colSpan={6} className='border-0 font-size-lg color-grey800 pt-4'>No data yet.</td></tr>

        return list.map((item, index) => {
            return (<tr key={index} className="color-grey800 align-middle table-tr td hover-bookmodal" onClick={() => props.goto(item.book_path)}>
                <td className="text-start text-truncate" style={{maxWidth: '300px'}}>
                    <img src={actions.getCoverUrl(item.book_path)} alt="img" height="40px" className="me-2"/>
                    {item.book_name}
                </td>
                <td>{item.book_meta.book_level}</td>
                <td className="">
                    <GetScore type='comprehension' data={item.comprehension} />
                </td>
                <td>
                    <GetScore type='speaking' data={item.speaking} meta={item.book_meta} />
                </td>
                <td>
                    <GetScore type='writing' data={item.writing} meta={item.book_meta} />
                </td>
                <td>{item.time_spent}</td>
                {/* <td className='font-weight-500'>{item.overall}</td> */}
            </tr>)
        })
    }

    return (
        <>
            <Row className="align-items-center mx-3 mb-4">
                <Col className='position-relative'>
                    <img src={process.env.PUBLIC_URL + '/images/search_icon.svg'} className="search-icon"  style={{left: "calc(15px + 1rem)"}} alt='search icon'/>
                    <FormControl
                        placeholder="Search books..."
                        aria-label="Username"
                        className="bg-color search-box color-grey700"
                        aria-describedby="basic-addon1"
                        style={{height: "48px"}}
                        onChange={handleChange}
                        value = {search}
                    />
                    <span className={`${clearBtn} clear-search color-grey800`} style={{right: '5%'}} onClick={()=> setSearch('')}>
                        <FontAwesomeIcon icon={faTimes} size={'1x'}/>
                    </span>
                </Col>
            </Row>
            <Table className="text-center table-sw">
                <thead className="bg-color curved-top">
                    <tr>
                        <td className='border-0'></td>
                        <td className='border-0'></td>
                        <td className='color-grey800' colSpan={3}>Most recent scores</td>
                        <td className='border-0'></td>
                    </tr>
                    <tr className="color-grey800 font-weight-600">
                        <td></td>
                        <td>LEVEL</td>
                        <td>COMPREHENSION</td>
                        <td>SPEAKING</td>
                        <td>WRITING</td>
                        <td>TOTAL TIME</td>
                        {/* <td>OVERALL</td> */}
                    </tr>
                </thead>
                <tbody>
                    <TableBody />
                </tbody>
            </Table>
            <ReactPaginate
                previousLabel={<span className="arrow-btn mx-2"><img src={process.env.PUBLIC_URL + '/images/right_arrow.svg'} width="7px"  className="rotate-180" alt='left arrow' /></span>}
                nextLabel={<span className="arrow-btn mx-2"><img src={process.env.PUBLIC_URL + '/images/right_arrow.svg'} width="7px" alt='right arrow' /></span>}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination align-items-center justify-content-end  mt-3"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}/>
        </>
    )
}
