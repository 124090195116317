import {client} from '../api/client'
import * as actions from "../actions/actiontypes"
const initialState = {labels: ["","","","","",""],series:[
        { id: 1, label: "Avg", value: [0,0,0,0,0,0], color: "primary" }
        //, { id: 2, label: "August", value: [2], color: "secondary" }
    ],list : []}

const classSummaryReducer = (state = initialState, action)=> {
    switch(action.type) {
        case actions.CLASSES_BARCHART_LOAD:
        return action.payload
        case actions.CLASSES_TABULAR_LIST:
        return action.payload
        default:
            return state;
    }
}
// Thunk function
export async function fetchClassSummary(dispatch, getState) {
    const response = await client.get(process.env.REACT_APP_API_BASE_URL+'/api/load/graph/average/level/<class_name>')
       response.data.series =
    [
        { id: 1, label: "Avg", value: response.data.series, color: "primary" },
        // { id: 2, label: "August", value: [2], color: "secondary" }
    ]

    dispatch({ type: actions.CLASSES_BARCHART_LOAD, payload: response.data })
}

export async function fetchTabularClassList(dispatch, getState) {
    const response = await client.post(process.env.REACT_APP_API_BASE_URL+'/api/student/average/score')
    let curr = {...getState().classSummary}
    curr.list = response.data
    dispatch({ type: actions.CLASSES_TABULAR_LIST, payload: curr })
}

export default classSummaryReducer;