import React from 'react'
import { Page, Text, View, Document, StyleSheet, Image, Font, PDFDownloadLink } from '@react-pdf/renderer';

import * as colorAPI from '../../api/color'

// Pdf document of Student report
export default function StudentReportPDF(props) {
    let noOfBook = props.reports.length

    Font.register({
        family: 'Quicksand',
        src: process.env.PUBLIC_URL + '/font/static/Quicksand-Regular.ttf',
        format: "truetype"
    });
    Font.register({
        family: 'QuicksandSemiBold',
        src: process.env.PUBLIC_URL + '/font/static/Quicksand-SemiBold.ttf',
        format: "truetype"
    });
    Font.register({
        family: 'QuicksandBold',
        src: process.env.PUBLIC_URL + '/font/static/Quicksand-Bold.ttf',
        format: "truetype"
    });
    const styles = StyleSheet.create({
        page: {
            paddingBottom: 50,
            paddingTop: 60,
            fontFamily: 'Quicksand'
        },

        header: {
            backgroundColor: '#DFE8FF',
            height: '14%',
            position: 'relative',
            bottom: 60,
            width: '100%'
        },
        header_text: {
            color: '#6079FF',
            fontSize: '7px',
            fontFamily: 'QuicksandSemiBold',
            fontWeight: '500',
            padding: '16px 32px',
        },
        header_logo: {
            padding: '8px 0px 0px 8px',
            width: '60px'
        },
        header_studentName: {
            fontWeight: '700',
            fontFamily: 'QuicksandBold',
            fontSize: '18px',
            color: '#6079FF',
            textAlign: 'center',
        },
        header_star_view: {
            position: 'relative',
            left: '-50%',
            top: '40%',
            display: 'flex',
            width: '65px',
            height: '65px',
            margin: 'auto',
            backgroundColor: '#6079FF',
            borderRadius: '50%',
        },
        header_star_img: {
            width: '55px',
            margin: 'auto'
        },
        absolute_center: {
            position: 'absolute',
            left: '50%',
            top: '50%'
        },
        header_star_text: {
            position: 'relative',
            fontFamily: 'QuicksandBold',
            left: '-50%',
            top: '-50%',
            fontWeight: '700',
            fontSize: '9px',
            color: '#1B2B7E',
        },

        body: {
            // position: 'relative',
            // top: '12%'
        },

        table: {
            display: "table",
            width: "65%",
            borderStyle: "solid",
            borderColor: '#dee2e6',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            marginHorizontal: 'auto',
            marginBottom: '20px',
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row",
            marginVertical: 0
        },
        tableRowColored: {
            margin: "auto",
            flexDirection: "row",
            backgroundColor: '#F5F8FD',
            marginVertical: 0
        },
        tableCol: {
            width: "25%",
            borderStyle: "solid",
            borderColor: '#dee2e6',
            borderWidth: 1,
            borderLeftWidth: 0,
            // borderTopWidth: 0,
        },
        tableCell: {
            margin: "8px",
            textAlign: 'center',
            fontSize: '10px',
            color: '#5D687F'
        },

        star_text_view: {
            position: 'absolute',
            left: '50%',
            top: '50%',
        },
        star_text: {
            position: 'relative',
            fontFamily: 'QuicksandBold',
            fontSize: '8.5px',
            fontWeight: 'bold',
            color: '#1B2B7E',
            top: '-50%',
            left: '-50%',
        },

        comprehension_cell: {
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            margin: 'auto',
        }
    });

    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                <Image style={styles.header_logo} src={`${process.env.PUBLIC_URL}/images/storyworld_logo.png`}/>
                {/* <Text style={styles.header_text}>STORYWORLD Report Card</Text> */}
                <Text style={styles.header_studentName}>{props.student.name}</Text>
                    <View style={styles.absolute_center}>
                        <View style={styles.header_star_view}>
                            <Image style={styles.header_star_img} src={`${process.env.PUBLIC_URL}/images/star.png`}/>
                            <View style={styles.absolute_center}>
                                <Text style={styles.header_star_text}>{props.total_point}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.body}>
                    <View style={styles.table}>
                        <View style={styles.tableRow} wrap={false}>
                            <View style={[styles.tableCol, {width: '50%'}]}>
                                <Text style={styles.tableCell}>Books Read - <Text style={{fontWeight: 700}}>{noOfBook}</Text></Text>
                            </View>
                            <View style={[styles.tableCol, {width: '25%'}]}>
                                <Text style={styles.tableCell}>Points</Text>
                            </View>
                            <View style={[styles.tableCol, {width: '25%'}]}>
                                <Text style={styles.tableCell}>Comprehension</Text>
                            </View>
                        </View>
                        {
                            props.reports.map((row, index) => {
                                return <View style={index%2 === 0? styles.tableRowColored: styles.tableRow} wrap={false} key={index}>
                                    <View style={[styles.tableCol, {width: '50%', flexDirection: 'row', alignItems: 'center'}]}>
                                        <Image
                                            cache={false}
                                            src={process.env.PUBLIC_URL + `/img/${row.book_path}/images/thumbnail.jpg`}
                                            style={{width: '24px'}}
                                        />
                                        <Text style={[styles.tableCell, {fontFamily: 'QuicksandBold', textAlign: 'left', width: '90%'}]}>{row.book_name}</Text>
                                    </View>
                                    <View style={[styles.tableCol, {width: '25%'}]}>
                                        <Image
                                            src={process.env.PUBLIC_URL + '/images/star.png'}
                                            style={{width: "30px", margin: 'auto'}}
                                        />
                                        <View style={styles.star_text_view}>
                                            <Text style={[styles.tableCell, styles.star_text]}>{row.points}</Text>
                                        </View>
                                    </View>
                                    <View style={[styles.tableCol, {width: '25%'}]}>
                                        <View style={[styles.comprehension_cell, {backgroundColor: colorAPI.Comprehension(row.comprehension, true)}]}></View>
                                    </View>
                                </View>
                            })
                        }
                    </View>
                    <View>
                        <Image src={process.env.PUBLIC_URL + '/images/Scoreboard.jpg'} style={{width: '60%', marginLeft: '100px'}}/>
                    </View>
                </View>
            </Page>
        </Document>
    );


    return (
        <div className="a4-size m-auto mb-4">
            <button type="button" className="bg-transparent color-blue500 btn btn-back btn-primary btn-sm d-flex ms-auto">
                <PDFDownloadLink document={<MyDocument />} fileName="Student Report.pdf" style={{textDecoration: 'none', color: 'inherit'}}>
                    {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Download Report'
                    }
                </PDFDownloadLink>
            </button>
            {/* <PDFViewer style={{width: '100%', height: '500px'}}>
                <MyDocument />
            </PDFViewer> */}
        </div>
    )
}
