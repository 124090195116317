function TotalSecondsFormat(seconds) {
    if (!seconds) return '';

    let duration = seconds;
    let hours = duration / 3600;
    duration = duration % (3600);
   
    let min = parseInt(duration / 60);
    duration = duration % (60);
   
    let sec = parseInt(duration);
   
    if (sec < 10) {
      sec = `0${sec}`;
    }
    if (min < 10) {
      min = `${min}`;
    }

    if (parseInt(hours, 10) > 0) {
      let duration = `${parseInt(hours, 10)}h`
      duration = (min > 0)?(duration+":"+min+"m"):duration      
      return duration
    }
    else if (min === 0)  {
      return `${sec}s`
    }  
    else {
      return `${min}:${sec}`
    }
  }

  export {TotalSecondsFormat}