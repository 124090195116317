import * as actions from "./actiontypes"
import * as s3utility  from '../api/s3utility'
import { client } from "../api/client"
export const increment = nr => ({
    type: actions.INCREMENT,
    payload: {
        newdata: nr
    }
})

//Recent Activity
export const load_recent_activity = ()=> ({
    type: actions.RA_DEFAULT_LOAD,
    payload:{}
})


export const getS3Audio=s3object=> {
    if (!s3object) return ''
    return s3utility.getAudio(s3object)
};

export const fetchWritingResponseById = s3object=>  {
    return s3utility.getTextFromGzipPromise(s3object)
}

export const getProfileModal = (teacher, student_id, lang, offset=0) => {
    let url = process.env.REACT_APP_API_BASE_URL+'/api/student/activity'
    return client.post(url, {
        "teacher_id": teacher,
        "student_id": student_id,
        "target_lang" : lang,
        "offset": offset
    })
}

export const getModalBook = (teacher, student, book_name, lang) => {
    const url = process.env.REACT_APP_API_BASE_URL+'/api/student/book/activity'
    return client.post(url, {
        "teacher_id": teacher,
        "student_id": student,
        "book_name" : book_name,
        "target_lang" : lang
    })
}

export const getCoverUrl = (path) => {
    return `https://swlb.storyworld.io/`+process.env.REACT_APP_THUMBNAIL_SRV_VERSION+`/8I5RAGGN2U40Q0/Books/dist/${path}/images/thumbnail.jpg`
    // let product_key = store.getState().recent.data.product;
    // return `https://swlb.storyworld.io/${product_key}/8I5RAGGN2U40Q0/Books/dist/${path}/images/thumbnail.jpg`
}

export const setResubmit = (req_data) => {
    let url = process.env.REACT_APP_API_BASE_URL+`/api/allow/resubmission`
    return client.post(url, req_data)
}

export const setOverall = (student, book_name, overall) => async dispatch => {
    if (!student || !book_name) return
    let url = process.env.REACT_APP_API_BASE_URL+`/api/assign/overall/score`
    const response = await client.get(url)
    console.log(response)
    dispatch({type:actions.SUBMIT_OVERALL,payload: response.data});
}

export const createScatterData = (data, filter) => {
    console.log(data)
}

export const setReqData = (req_data) => ({
    type:actions.SET_REQ_DATA,
    payload: req_data
})

export const getAssignedBook = (req_data) =>{
    let url = process.env.REACT_APP_API_BASE_URL+`/api/assigned/book/detail`
    return client.post(url, req_data)
}

export const getNewPage = (data) => {
    let url = process.env.REACT_APP_API_BASE_URL + '/api/class/activity'
    return client.post(url, data)
}

export const getStudentReport = (req_data) => {
    let url = process.env.REACT_APP_API_BASE_URL+'/api/student/progress/report'
    return client.post(url, req_data)
}

export const logError = (error) => {
    let url = process.env.REACT_APP_API_BASE_URL+'/api/log/error'
    return client.post(url, error)
}

export const getCounter = (count) => dispatch => {
    dispatch({type:actions.INCREMENT,payload: count});
}
//phonics search
export const setPhonicsQuery = (q) => dispatch => {
    dispatch({type:actions.SET_PHONICS_SEARCH_QUERY,payload: q});
}
export const getPhonicsSearchResult = () => dispatch => {
    dispatch({type:actions.GET_PHONICS_SEARCH_RESULT,payload: {}});
}


