import React from "react";
import Chartist from "react-chartist";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import axistitle from "chartist-plugin-axistitle";
import { Pie, Scatter } from "react-chartjs-2";

export const SalesValueChart = () => {
  const data = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    series: [[1, 2, 2, 3, 3, 4, 3]],
  };

  const options = {
    low: 0,
    showArea: true,
    fullWidth: true,
    axisX: {
      position: "end",
      showGrid: true,
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: false,
      labelInterpolationFnc: (value) => `$${value / 1}k`,
    },
  };

  const plugins = [ChartistTooltip()];

  return (
    <Chartist
      data={data}
      options={{ ...options, plugins }}
      type="Line"
      className="ct-series-g ct-double-octave"
    />
  );
};

export const SalesValueChartphone = () => {
  return new Chartist.Line(
    ".ct-chart",
    {
      labels: [1, 2, 3],
      series: [
        [
          { meta: "description", value: 1 },
          { meta: "description", value: 5 },
          { meta: "description", value: 3 },
        ],
        [
          { meta: "other description", value: 2 },
          { meta: "other description", value: 4 },
          { meta: "other description", value: 2 },
        ],
      ],
    },
    {
      plugins: [Chartist.plugins.tooltip()],
    }
  );
  // <Chartist data={data} options={{...options, plugins}} type="Line" className="ct-series-g ct-major-tenth" />
};

export const CircleChart = (props) => {
  const { series = [], donutWidth = 20 } = props;
  const sum = (a, b) => a + b;

  const options = {
    low: 0,
    high: 8,
    donutWidth,
    donut: true,
    donutSolid: true,
    fullWidth: false,
    showLabel: false,
    labelInterpolationFnc: (value) =>
      `${Math.round((value / series.reduce(sum)) * 100)}%`,
  };

  const plugins = [ChartistTooltip()];

  return (
    <Chartist
      data={{ series }}
      options={{ ...options, plugins }}
      type="Pie"
      className="ct-golden-section"
    />
  );
};

export const CompBarChart = (props) => {
  const { labels = [], series = [], chartClassName = "ct-comp" } = props;
  const data = { labels, series };
  // console.log(data)
  const options = {
    low: 0,
    showArea: false,
    axisX: {
      position: "end",
    },
    axisY: {
      showLabel: false,
      offset: 0,
    },
  };

  const plugins = [
    ChartistTooltip({
      tooltipFnc: (data, value) => {
        return value+'%';
      },
    }),
  ];

  let drawEvent = (context) => {
    if (context.type === "bar") {
      let color = "graph-red";
      if (context.series[context.index] > 75) color = "graph-green";
      else if (context.series[context.index] > 49) color = "graph-yellow";
      context.element.addClass(color);
    }
  };

  return (
    <Chartist
      data={data}
      options={{ ...options, plugins }}
      type="Bar"
      className={chartClassName}
      listener={{ draw: (c) => drawEvent(c) }}
    />
  );
};

export const BarChart = (props) => {
  const {
    labels = [],
    series = [],
    chartClassName = "ct-golden-section",
  } = props;
  const data = { labels, series };
  // console.log(data)

  const options = {
    low: 0,
    axisX: {
      position: "end",
      showLabel: true,
    },
    axisY: {
      showGrid: true,
      showLabel: true,
      title: true,
      onlyInteger: true,
      offset: 50,
    },
  };

  const plugins = [
    // ChartistTooltip() ,
    // ChartistTooltip({transformTooltipTextFnc: (data, a)=> {
    //   console.log(data, a)
    //   return "random"+data
    // }}),
    ChartistTooltip({
      tooltipFnc: (data, a) => {
        var txt = document.createElement("textarea");
        txt.innerHTML = data;
        return (
          "<span class='student-name'>" +
          JSON.parse(txt.value).data.join("<br>") +
          "</span>"
        );
      },
    }),
    axistitle({
      axisY: {
        axisTitle: "Number of Students",
        axisClass: "ct-axis-title",
        offset: {
          x: 0,
          y: 15,
        },
        flipTitle: true,
      },
    }),
  ];

  let drawEvent = (context) => {
    // if (context.type == 'bar') console.log(context)
    // console.log(context)
  };

  return (
    <Chartist
      data={data}
      options={{ ...options, plugins }}
      type="Bar"
      className={chartClassName}
      listener={{ draw: (c) => drawEvent(c) }}
    />
  );
};

// export const ScatterChart = (props) => {
//   const { labels = [], series = [], chartClassName = "ct-golden-section scatterplot" } = props;
//   const data = { labels, series };
//   // console.log(data)
//   const options = {
//     low: 0,
//     showLine: false,
//     axisX: {
//       position: 'end'
//     },
//     axisY: {
//       showGrid: true,
//       showLabel: true,
//       onlyInteger: true,
//       offset: 50,
//       // labelInterpolationFnc: function (value) {
//       //   console.log(value)
//       //   return 1;
//       // }
//     },
//     high: 100
//   };

//   const foo = i => {return i + `end`}

//   const plugins = [
//     ChartistTooltip(),
//     // ChartistTooltip({transformTooltipTextFnc: (data, i)=> {
//     //   return "random"+foo(data) + i
//     // }})
//     ChartistTooltip({tooltipFnc: (data, a)=> {
//       // console.log(data, a)
//       return data;
//     }}),
//     axistitle({
//       axisY: {
//         axisTitle: "Average Comprehension Score  (%)",
//         axisClass: "ct-axis-title",
//         offset: {
//           x: 0,
//           y: 15
//         },
//         flipTitle: true
//       }
//     })
//   ]

//   let drawEvent = (context) => {
//     if(context.type === 'point') {
//         let color = 'graph-red'
//         if ( context.value.y>75) color = 'graph-green'
//         else if ( context.value.y>49) color = 'graph-yellow'
//         context.element.addClass(color);
//       }
//   }

//   return (
//     <Chartist data={data} options={{...options, plugins}} type="Line" className={chartClassName} listener={{draw: c => drawEvent(c)}}/>
//   );
// }

export const ScatterChartTime = ({ chartData, step, max }) => {
  return (
    <Scatter
      data={{ datasets: chartData }}
      height={200}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            bodyColor: '#5D687F',
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: '#CBD5E1',
            displayColors: false,
            yAlign: 'bottom',
            callbacks: {
              label: function (context) {
                return context.dataset.label;
              },
            },
          },
        },
        scales: {
          x: {
            min: "00:00",
            max: max,
            offset: 'true',
            type: "time",
            ticks: {
              padding: 15,
              maxRotation: 0,
              minRotation: 0,
              callback: function(value, index, values) {
                if (index === values.length-1 && value === '59:00') return "60+"
                return value
              }
            },
            time: {
              parser: "mm:ss",
              unit: "second",
              stepSize: step,
              displayFormats: {
                second: "mm:ss",
              },
            },
            title: {
              display: true,
              text: 'Total Time Reading (minutes)',
              color: '#5D687F',
              font: {
                family: 'Quicksand',
                size: 16,
                // weight: 'bold',
                // lineHeight: 16,
              },
              padding: {top: 10, left: 0, right: 0, bottom: 20}
            },
            grid: {
              color: '#F8FAFF',
              lineWidth: 3,
              drawTicks: false,
              // borderColor: "#fff",
              borderWidth: 0
            },
          },
          y: {
            min: 0,
            max: 100,
            offset: 'true',
            // color: "#fff",
            ticks: {
              stepSize: 10,
              padding: 10,
            },
            title: {
              display: true,
              text: 'Comprehension Score (%)',
              color: '#5D687F',
              font: {
                family: 'Quicksand',
                size: 14,
                // weight: 'bold',
                // lineHeight: 16,
              },
              padding: {top: 20, left: 0, right: 0, bottom: 0}
            },
            grid: {
              color: '#F8FAFF',
              lineWidth: 3,
              drawTicks: false,
              // borderColor: "#fff",
              borderWidth: 0
            },
          },
        },
        GridLineOptions: {
          display: false,
        },
      }}
    />
  );
};

export const ScatterChartLvl = ({ chartData, labels }) => {
  return (
    <Scatter
      data={{ datasets: chartData }}
      height={210}
      options={{
        responsive: true,
        animation: {
            duration: 0
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            bodyColor: '#5D687F',
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: '#CBD5E1',
            displayColors: false,
            yAlign: 'bottom',
            callbacks: {
              label: function (context) {
                return context.dataset.label;
              },
            },
          },
        },
        scales: {
          x: {
            min: 1,
            max: 5,
            offset: 'true',
            ticks: {
              stepSize: 1,
              // labelOffset: 50,
              padding: 15,
              callback: function(value, index, values) {
                if (labels) {
                  return labels[index];
                }
              }
            },
            gridLines: {
              drawBorder: false,
              display: false,
            },
            title: {
              display: true,
              text: 'Average Book Level',
              color: '#5D687F',
              font: {
                family: 'Quicksand',
                size: 14,
                weight: 500,
                // lineHeight: 1.2,
              },
              padding: {top: 10, left: 0, right: 0, bottom: 10},
            },
            grid: {
              // color: '#fff',
              drawTicks: false,
              // drawBorder: false,
              // borderColor: "#fff",
              borderWidth: 0,
              offset: true
            },
          },
          y: {
            min: 0,
            max: 100,
            color: "#fff",
            offset: 'true',
            ticks: {
              stepSize: 10,
              padding: 10,
            },
            title: {
              display: true,
              text: 'Average Comprehension Score (%)',
              color: '#5D687F',
              font: {
                family: 'Quicksand',
                size: 14,
                weight: 500,
                // lineHeight: 1.2,
              },
              padding: {top: 20, left: 0, right: 0, bottom: 0},
            },
            grid: {
              // color: '#fff',
              drawTicks: false,
              // drawBorder: false,
              // borderColor: "#fff",
              borderWidth: 0
            },
          },
        },
        // GridLineOptions: {
        //   display: false,
        // },
      }}
    />
  );
};

export const PieChartTime = ({charData}) => {
  return <Pie
    data = {{
      labels: [
        'Story',
        'Quiz'
      ],
      datasets: [{
        label: 'My First Dataset',
        data: charData,
        backgroundColor: [
          '#f0f4fe',
          '#e0e8fd'
        ],
        hoverOffset: 4
      }]
    }}
    height={75}
    options= {{
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return `${context.label}: ${context.formattedValue} mins`
            },
          },
          displayColors: false,
          backgroundColor: '#4a5267'
        },
      },
      animation: {
        duration: 0
      },
    }}
  />
}