import * as actions from "../actions/actiontypes"
import { findByPath,READERS_TYPE, getReaders,filterReadersType } from "../api/books"
const initialState = () => {
    return {
        "PRE_READERS": filterReadersType(READERS_TYPE.PRE_READERS),
        "EMERGENT": filterReadersType(READERS_TYPE.EMERGENT),
        "READING_FLUENCY": filterReadersType(READERS_TYPE.READING_FLUENCY)
    }
}

const phonicsSeaerchReducer = (state = initialState(), action)=> {

    switch(action.type){
        //case actions.GET_PHONICS_SEARCH_RESULT:
        case actions.SET_PHONICS_SEARCH_QUERY:
            if(action.payload){
                const q = action.payload
                let data = initialState()

                //items
                // {"book_path": "LB_CKX",
                // "highfreq_words": null,
                // "phonics": "back, backpack, sick, lock, truck, luck",
                // "readers_type": "emergent-readers",
                // "unit_num": 33,
                // "unit_title": "ck - consonant team"}

                const EMERGENT =  data.EMERGENT.filter(unit=>{
                    if(unit.row_data[0].unit_title.toLowerCase().startsWith(q) || unit.row_data[0].unit_num == q) return unit
                }).sort((a, b) => a.unit_title - b.unit_title);

                const PRE_READERS = data.PRE_READERS.filter(unit=>{
                    if(unit.row_data[0].unit_title.toLowerCase().startsWith(q) || unit.row_data[0].unit_num == q) return unit
                }).sort((a, b) => a.unit_title - b.unit_title);
                const READING_FLUENCY = data.READING_FLUENCY.filter(unit=>{
                    if(unit.row_data[0].unit_title.toLowerCase().startsWith(q) || unit.row_data[0].unit_num == q) return unit
                }).sort((a, b) => a.unit_title - b.unit_title);
                return { PRE_READERS, EMERGENT,  READING_FLUENCY };
            } else return initialState()
        default:
            return  state
    }
}
export default phonicsSeaerchReducer;