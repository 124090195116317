import {client} from '../api/client'
import * as actions from "../actions/actiontypes"
import { TeacherEmail } from '../api/teacher'
const initialState = []

const dashboardReducer = (state = initialState, action)=> {
    switch(action.type) {
        case actions.DASHBOARD_LOAD:
        return action.payload
        default:
            return state;
    }
}
// Thunk function
export async function fetchDashboardConfig(dispatch, getState) {

    const response = await client.get(process.env.REACT_APP_API_BASE_URL+'/api/load/classes/'+TeacherEmail())
    if(response['classes'])
    // console.log(response.classes)
    dispatch({ type: actions.DASHBOARD_LOAD, payload: response.classes })
}

export default dashboardReducer;