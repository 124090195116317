const css_map = [{ range: "76-100", value: "color-green",   colorCode: '#75CF73', fadedColorCode: '#D4F4D3', bgClass: 'bg-green50' },
                 { range: "51-75",  value: "color-orange",  colorCode: '#F8C837', fadedColorCode: '#FFECB1', bgClass: 'bg-yellow50' },
                 { range: "0-50",   value: "color-red",     colorCode: '#F06184', fadedColorCode: '#FFC3D1', bgClass: 'bg-red50' }]

const Comprehension = (score, assigned=false) => {
    if (score == null || score<0 || score>100) {
        if (assigned) return '#F06184'
        return "color-red"
    }
    let color = css_map.find(item=>{
        let beginning = item.range.split('-')[0],end = item.range.split('-')[1]
        if(score<=parseInt(end) && score>=parseInt(beginning)) return item
        return null
    })
    if (assigned) return color.colorCode
    return color.value
}

const Assigned_Comp = (score, isOverall = true) => {
    if (score == null || score<0 || score>100) {
        if (isOverall) return {color: '#EDF1F8', bgClass: ''}
        return {color: '#BEC7D8', bgClass: 'bg-grey100'}
    }
    let color = css_map.find(item=>{
        let beginning = item.range.split('-')[0],end = item.range.split('-')[1]
        if(score<=parseInt(end) && score>=parseInt(beginning)) return item
        return null
    })
    if (isOverall) return {color: color.fadedColorCode}
    return {color: color.colorCode, bgClass: color.bgClass}
}

const Overall = (score) => {
    const css_map = [{ range: "8-10", value: "#75CF73" },
    { range: "5-7", value: "#F49F26" },
    { range: "1-4", value: "#F06184" },
    { range: "0-0", value: "#F0F5FC" }
]
    if (!score) return "#F06184"
    return css_map.find(item=>{
        let beginning = item.range.split('-')[0],end = item.range.split('-')[1]
        if(score<=end && score>=beginning) return item
        return null
    }).value
}

const Comp_graph = (score) => {
    const css_map = [{ range: "76-100", value: "#75CF73" },
        { range: "51-75", value: "#FFD760" },
        { range: "0-50", value: "#F06184" }
    ]

    if (score == null) return '#9FABBF' // retrun grey if null
    let color = css_map.find(item=>{
        let beginning = item.range.split('-')[0],end = item.range.split('-')[1]
        if(score<=end && score>=beginning) return item
        return null
    })
    if (color) return color.value
}

export { Comprehension, Assigned_Comp, Overall, Comp_graph };