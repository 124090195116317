import AWS from 'aws-sdk';
const creds = {
    bucket: 'swabc',
    access_key: 'AKIAU5LONR7ZN556TYKS',
    secret_key: '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'
}
const getAudio = s3object =>{
    AWS.config.update({ accessKeyId: creds.access_key, secretAccessKey: creds.secret_key });
    AWS.config.region = 'us-east-1';
    var s3 = new AWS.S3({ params: { Bucket: creds.bucket } });
    var params =  {
        Bucket: creds.bucket,
        // Key: '0018bbc6-e5dd-a5ff-f162-3b53c1065363.mp3',
        Key: s3object,
    };
    return s3.getSignedUrl('getObject', params);
}

const getTextFromGzipPromise= (s3object) => {
    AWS.config.update({ accessKeyId: creds.access_key, secretAccessKey: creds.secret_key });
    AWS.config.region = 'us-east-1';
    var s3 = new AWS.S3({ params: { Bucket: creds.bucket } });
    var params =  {
        Bucket: creds.bucket,        
        Key: s3object,
    };
    return s3.getObject(params).promise()
}
export { getAudio, getTextFromGzipPromise };
