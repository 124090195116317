import * as actions from "../actions/actiontypes"

const counterReducer = (state = 0, action)=> {
    switch(action.type){
        case actions.INCREMENT:
            return state + action.payload.newdata;
        case 'DECREMENT':
            return state - 1;
        default:
            return state;
    }
}
export default counterReducer;