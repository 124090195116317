import React, { Component, useEffect, useLayoutEffect, useRef, useState } from "react";
import PhonicsCards from '../PhonicsComponents/PhonicsCards'
import BookReport from '../PhonicsComponents/BookReport'
import * as actions from "../../actions"
import store from '../../store'
import Pagination from '../PhonicsComponents/Pagination'
import ReactPaginate from 'react-paginate';
import {READERS_TYPE, getLessonPlans} from "../../api/books"
import { useLocation } from "react-router-dom";
import { unit_map } from "../../api/unit_map";
export default (props) => {
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const LABEL_NO_RECORD = "Phonics unit not found."
    const [state, setstate] = useState({
        query: '',
        list: {PRE_READERS:[], EMERGENT:[],  READING_FLUENCY:[]}
      })

    const handleChange2 = (e) => {
        if (e.target.value !== "" || e.target.value !== null) {
            store.dispatch(actions.setPhonicsQuery(e.target.value))
        }
        const results = store.getState().phonicsSearch
        setstate({
            query: e.target.value,
            list: results
        })
    }

    const TabHeading = () => (
        <>
            <p>Pre-reader units are designed for students who are ready to start learning to read and write.</p>
            <li><strong>Phonics Book</strong>: Use the phonics book to introduce a new letter to your students. </li>
            <li><strong>Storybook</strong>: Bring the letter and reading experience to life with the story.</li>
            <li><strong>Word Book</strong>: Show how the letter appears in words and sentences.</li>
            <br />
        </>
    )

    const UnitHead = (unit) => {
        const props = unit.data        
        const prereader_active_color="#fbf1cf";
        const emerging_reader_active_color="#e2eedb";
        const reading_fluency_active_color="#f8e5d7";
        let active_color = prereader_active_color
        if (READERS_TYPE.PRE_READERS.label === props["readers_type"])
            active_color = prereader_active_color
        if (READERS_TYPE.EMERGENT.label === props["readers_type"])
            active_color = emerging_reader_active_color
        if (READERS_TYPE.READING_FLUENCY.label === props["readers_type"])
            active_color = reading_fluency_active_color
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="unit-bar">
                            <span className="heading"> Unit {props.unit_num}: <strong style={{ color: "#101828", marginRight: "20px" }} >{props.unit_title}</strong></span>
                            { unit && props.unit_num!==1?
                                <a  target="_blank" href={getLessonPlans(props.unit_num.toString(),props["readers_type"])}
                                    className="btn btn-sm disabled1" role="button" aria-disabled="true" style={{border:"none"}}>
                                <span className="lesson-plan" style={{background:active_color,padding:"7px"}}> Lesson Plan&nbsp;&nbsp;
                                    <img src={process.env.PUBLIC_URL + '/images/phonics-download.png'} style={{ marginBottom: "6px" }} />
                                </span>
                            </a>:'' }
                            {/* </a> */}
                        </div>
                    </div>
                </div>
            </>
    )}



    const CardRow = (books,index) => {
        const row = books.item
        return (<>
                    <div className="container phonics-card-row-wrapper">
                        <UnitHead data={row[0]} key={"unit-row-" + index} />
                        <div className="row" key={"book-index-"+index}>
                            {
                                row[2]?
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <PhonicsCards data={row[2]} />
                            </div>:''}
                            {
                                row[1]?

                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <PhonicsCards data={row[1]} />
                            </div>:''}
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <PhonicsCards data={row[0]} />
                            </div>
                            {
                                books.book_report_exists?
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                <BookReport data={row[0]} />
                            </div>:''}
                        </div>
                    </div>
        </>)
    }

    const GetPhonicsType = (props) => {
        const list = []


    // To hold the actual data
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(150);





    const [blogPosts, setBlogPosts] = useState([]);

    const [postsPerPage] = useState(3);

    // ...

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = ({ selected }) => {
       setCurrentPage(selected + 1);
    };




    useEffect(() => {
        setData(props.phonics_type);
        //setBlogPosts(props.phonics_type);

        setLoading(false);
    }, [])


    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(data.length / recordsPerPage)



    currentRecords.forEach((item,index) => {
            if(item) {
                // if(props.book_report_exists){
                //     console.log(props.book_report_exists)
                //     console.log(item.row_data)
                //     //item.row_data = [...item.row_data,...item.row_data]
                // }
                list.push(<CardRow item={item.row_data} index={index} key={"card-row-" + index} book_report_exists={props.book_report_exists} />)
            }
        });

        // props.phonics_type.forEach((item,index) => {
        //     if(item)
        //     list.push(<CardRow item={item.row_data} index={index} key={"card-row-" + index} />)
        // });
        return <>


       {list}

       {/* {data ? (
            <div className="blog-content-section">
               <ReactPaginate
                  onPageChange={setCurrentPage}
                  pageCount={nPages}
                  previousLabel={'Prev'}
                  nextLabel={'Next'}
                  containerClassName={'pagination'}
                  pageLinkClassName={'page-number'}
                  previousLinkClassName={'page-number'}
                  nextLinkClassName={'page-number'}
                  activeLinkClassName={'active'}
               />
            </div>
         ) : (
            <div className="loading">Loading...</div>
         )} */}

       {/* <div>
            <Pagination
    nPages={nPages}
    currentPage={currentPage}
    setCurrentPage={setCurrentPage}/>
            </div>                   */}

       </>;
    }

    const PhonicsBody = () => {
        const phonicsItems = store.getState().phonicsSearch
        return (           <> <nav className="sw-tab">
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button  style={{paddingTop:"unset",paddingBottom:"unset"}} className="nav-link active active-pre-readers" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                    <div>
                        <p style={{marginBottom:"unset"}}>Pre-reading</p>
                        <p style={{marginBottom:"unset",lineHeight:"initial",fontSize:"x-small"}}>(Letters A-Z; early digraphs)</p>
                    </div>
                </button>
                <button className="nav-link active-emerging-readers" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                    <div>
                        <p style={{marginBottom:"unset"}}>Emerging Reading</p>
                        <p style={{marginBottom:"unset",lineHeight:"initial",fontSize:"x-small"}}>(Long vowels, digraphs & diphthongs)</p>
                    </div>                    
                </button>
                <button className="nav-link active-reading-fluency" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" style={{marginRight:"auto"}}>
                    <div>
                        <p style={{marginBottom:"unset"}}>Reading Fluency</p>
                        <p style={{marginBottom:"unset",lineHeight:"initial",fontSize:"x-small"}}>(Grammar, high-level texts, book reports)</p>
                    </div>     
                </button>

                <div id="topbarSearch">
                <div className="input-group-merge search-bar input-group">
                    <span className="input-group-text" style={{ backgroundColor: "transparent", paddingRight: "1px", paddingLeft: "5px" }}>
                        <img src={process.env.PUBLIC_URL + '/images/icon-phonics-search.png'} style={{ width: "85%" }} />                                                                </span>
                    <input placeholder="Search" type="text"
                        className="form-control phonics-search-text"
                        value={state.query}
                        onChange={handleChange2}
                        onKeyUp={handleChange2}
                        onPaste={handleChange2}
                        onCut={handleChange2}
                        onInput={handleChange2}
                        onKeyDown = {handleChange2}
                        />
                        {/* title="Search By Book Name, Phonics, H-F words, Phonics Lesson, Unit Number" */}
                        <img src={process.env.PUBLIC_URL + '/images/help-icon-17005.png'}

                            data-toggle="tooltip" data-placement="top" title="Search By Phonics Lesson, Unit Number"
                            style={{
                                height: "30px",
                                paddingLeft: "2px",
                                paddingRight: "2px",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                marginTop: "3px",
                                marginLeft: "2px",
                            }} />
                    </div>
            </div>

                {/* <SearchBar onChange={handleChange} /> */}
        </div>
        <br />
        {/* <div className="sw-tab-heading-text">
            <TabHeading />
        </div> */}
    </nav>
    <div className="tab-content" id="nav-tabContent" >
        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div style={{ overflowY: "scroll", height: "60vh" }} >
                {(state.query === '' ? <GetPhonicsType phonics_type={phonicsItems.PRE_READERS} /> : !state.list.PRE_READERS.length ? LABEL_NO_RECORD :
                    <GetPhonicsType phonics_type={phonicsItems.PRE_READERS}/>
                )}
            </div>
        </div>
        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div style={{overflowY:"scroll",height:"60vh"}} >
                        {(state.query === '' ? <GetPhonicsType phonics_type={phonicsItems.EMERGENT} /> : !state.list.EMERGENT.length ? LABEL_NO_RECORD :
                            <GetPhonicsType phonics_type={phonicsItems.EMERGENT}/>
                        )}
            </div>
        </div>
        <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
            <div style={{overflowY:"scroll",height:"60vh"}} >
                        {(state.query === '' ? <GetPhonicsType phonics_type={phonicsItems.READING_FLUENCY} book_report_exists={true} /> : !state.list.READING_FLUENCY.length ? LABEL_NO_RECORD :
                            <GetPhonicsType phonics_type={phonicsItems.READING_FLUENCY}  book_report_exists={true} />
                        )}
            </div>
        </div>
    </div>
</>)
    }

    if (queryParameters.get("unit")) {
      const selection = unit_map.filter((x) => {
        return x.unit_num == queryParameters.get("unit");
      });
      if (selection.length > 0)
        return (
          <CardRow
            item={unit_map.filter((x) => {
              return x.unit_num == queryParameters.get("unit");
            })}
            index={0}
          />
        );
    }
    return PhonicsBody()
}