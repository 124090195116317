import counterReducer from "./counter";
import recentActivityReducer from "./recentActivity";
import assignedBookReducer from "./assignedBooks";
import classSummaryReducer from "./classSummary";
import {combineReducers} from 'redux';
import dashboardReducer from "./dashboard";
import libraryReducer from "./library";
import phonicsSearchReducer from "./phonicsSearch";

const allReducers = combineReducers({
    counter : counterReducer,
    phonicsSearch : phonicsSearchReducer,
    recent : recentActivityReducer,
    assignedList : assignedBookReducer,
    classSummary : classSummaryReducer,
    library : libraryReducer,
    dashboardConfig: dashboardReducer
})
export default allReducers;