import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { READERS_TYPE, findByPath,getBookType,getActivitySheetURL,isLetterBook,csvToArray } from "../../api/books"
import {Popover,OverlayTrigger} from 'react-bootstrap';

export default (props) => {
    const getCoverUrl = (path) => {
        return `https://swlb.storyworld.io/` + process.env.REACT_APP_THUMBNAIL_SRV_VERSION + `/8I5RAGGN2U40Q0/Books/dist/${path}/images/thumbnail.jpg`
    }
    const APP_URL = process.env.NODE_ENV != "development" ? process.env.REACT_APP_RAILS_PROD_URL:process.env.REACT_APP_RAILS_DEV_URL
    const mkLibraryURL = (path) => { return APP_URL+'/?book='+path+'&lang=EN-ES&go_to_library=true' }
    const mkGameURL = (path) => { return APP_URL+'/?book='+path+'&lang=EN&to_games=true' }
    const [popoverContent, setPopoverContent] = useState({"title":"","phonics":"","hf_words":""});
    const popoverTop = (
        <Popover id="popover-basic" style={{width:"250px"}}>
            <Popover.Title as="h3">{popoverContent.title}</Popover.Title>
            <Popover.Content>
                <b>{isLetterBook(props.data.book_path)?"Phonics Lesson":"Phonics Lesson"}:</b> <br/>{popoverContent.phonics}
                {getBookType(props.data.book_path)==="Storybook" && popoverContent.hf_words!==null?(<><br/><b>{props.data.readers_type==="pre-readers" ?"Decodable H-F Words:":"H-F Words:"}</b> <br/>{popoverContent.hf_words}</>):''}
            </Popover.Content>
        </Popover>
    );

    return (
        <div className="phonics-card">
            <div className="card mb-3" style={{ maxWidth: "450px" }}>
                <div className="row g-0">
                    <div className="col-md-4">
                        <a href={mkLibraryURL(props.data.book_path)}>
                            <div className="book-cover">
                                <img src={getCoverUrl(props.data.book_path)} className="img-fluid rounded-start" alt="..." />
                            </div>
                        </a>
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <div style={{height:"120px"}}>
                            <p>
                                <span className="badge rounded-pill bg-secondary">
                                    {getBookType(props.data.book_path)==='Storybook'?'Story':getBookType(props.data.book_path)}
                                </span>


                            </p>
                            <a href={mkLibraryURL(props.data.book_path)}>
                                <span className="card-title">{findByPath(props.data.book_path).title["EN"]}</span> <br />
                            </a>
                            {/* <span className="card-text keywords">Words: {csvToArray(props.data.phonics,5)}</span> */}
                                <span className="card-text keywords">
                                    Words: {csvToArray(props.data.phonics, 3)}

                                    {csvToArray(props.data.phonics, 3).split(",").length < props.data.phonics.split(",").length || getBookType(props.data.book_path)==="Storybook"?(<>, &nbsp;                                    
                                    <OverlayTrigger trigger="focus" placement="top" overlay={popoverTop}>
                                        <a href="#" onClick={(e) => {
                                            setPopoverContent({
                                                "title": findByPath(props.data.book_path).title["EN"],
                                                "phonics": props.data.phonics,
                                                "hf_words": props.data.highfreq_words
                                            }); e.preventDefault()
                                        }}>See All</a>
                                    </OverlayTrigger></>):""}
                                    
                                </span>
                            {/* <p className="card-text highfreq-words"><small className="text-muted">HF: {props.data.highfreq_words}</small></p> */}
                            </div>
                            {!isLetterBook(props.data.book_path)?<div className="action-rows" style={{display:"none1"}}>
                                <img src={process.env.PUBLIC_URL + '/images/icon-gaming-console.png'} className="display: displayinline" />
                                <a href={mkGameURL(props.data.book_path)}>
                                    Games
                                </a>
                            </div>:''}
                            {props.data.book_path!='LB_ALP'?
                            <div className="col-sm-12 action-rows">                                
                                <img src={process.env.PUBLIC_URL + '/images/phonics-download.png'} className="display: displayinline" />
                                <a target="_blank" href={getActivitySheetURL(props.data.book_path)}>Activity Sheets</a>
                            </div>:''}
                        </div>
                    </div>
                </div>
            </div>
        </div>




    )
}