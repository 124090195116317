import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Header from "./components/Header";
import PhonicsHeader from '../pages/PhonicsComponents/PhonicsHeader'
import PhonicsTab from './PhonicsComponents/PhonicsTab'
export default () => {
    return (
        <>
            <Header/>
            <div className="container phonics-container" >

                <PhonicsHeader />

                <PhonicsTab />
            </div>
        </>
    );
};