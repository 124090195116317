
import React, { useEffect, useState } from 'react'
import { Card, Col, FormControl, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';

import {TotalSecondsFormat} from '../api/text_format'
import store from '../store'
import * as actions from "../actions/"
import GetScore from './components/GetScore';

// Recent Activity tab
export default function RecentActivity (props) {
    const [listOriginal, setListOriginal] = useState([])
    const [listPagination, setListPagination] = useState([])
    const [list, setList] = useState([])
    const [paginationData, setPaginationData] = useState({
        perPage: parseInt(process.env.REACT_APP_DATA_LIMIT),
        offset: 0,
        currentPage: 0
    })
    const pageCount =  Math.ceil(listPagination.length / paginationData.perPage)

    const [search, setSearch] = useState('')
    const [filterBy, setFilterBy] = useState('student_name')
    const [clearBtn, setClearBtn] = useState('d-none')

    const updateData = () => {
        setList(listPagination.slice(paginationData.offset, paginationData.offset + paginationData.perPage))
    }
    const recent = store.getState().recent.data.recent_activity
    useEffect(() => {
        setListOriginal(store.getState().recent.data.recent_activity)
    }, [recent])

    useEffect(() => {
        setListPagination(listOriginal)
        updateData()
    }, [listOriginal])

    useEffect(() => {
        updateData()
    }, [listPagination])

    useEffect(() => {
        updateData()
    }, [paginationData])

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * paginationData.perPage;

        setPaginationData({
            ...paginationData,
            currentPage: selectedPage,
            offset: offset
        })
    };

    useEffect(() => {
        // Show clear button('X') if something is typed
        if (search) {
            setClearBtn('')
        }
        else {
            setClearBtn('d-none')
        }
        setListPagination(listOriginal.filter(data => data[filterBy].toLowerCase().includes(search.toLowerCase())))
        setPaginationData({         // Set page to 1 when search
            ...paginationData,
            offset: 0,
            currentPage: 0
        })
    }, [search])
    useEffect(() => {
        document.getElementById('searchBox_recent').focus()
    }, [list])

    const handleChange = (event) => {
        setSearch(event.target.value)
    }

    const SearchFilter = () => {
        const hoverEffect = (id, add=false) => {
            if (add) document.getElementById(id).getElementsByClassName('search-dropdown')[0].classList.add('search-dropdown-hover')
            else document.getElementById(id).getElementsByClassName('search-dropdown')[0].classList.remove('search-dropdown-hover')
        }
        return (
            <Row className="align-items-center me-3">
                <Col className="my-1 position-relative" id="recent-search">
                    <img src={process.env.PUBLIC_URL + '/images/search_icon.svg'} className="search-icon" style={{left: "calc(15px + 1rem)"}} alt='search icon'/>
                    <FormControl
                        placeholder={filterBy === 'book_name'? "Search books..." : "Search student...  "}
                        aria-label="Username"
                        className="bg-color search-box color-grey700"
                        aria-describedby="basic-addon1"
                        id="searchBox_recent"
                        style={{height: "48px"}}
                        onChange={handleChange}
                        value = {search}
                        onMouseOver={() => hoverEffect('recent-search', true)}
                        onMouseLeave={() => hoverEffect('recent-search')}
                    />
                    <span className={`${clearBtn} clear-search color-grey800`} onClick={()=> setSearch('')}>
                        <FontAwesomeIcon icon={faTimes} size={'1x'}/>
                    </span>
                    <select className="form-select-sm search-dropdown color-blue500 font-weight-600" value={filterBy} onChange={(event) => setFilterBy(event.target.value)}>
                        <option value='book_name'>Books</option>
                        <option value='student_name'>Student</option>
                    </select>
                </Col>
            </Row>
        )
    }
    const CustToolTip = () => {
        return  <div>
                    <span className="spnDetails"></span>
                    <span className="spnTooltip">
                        <strong>Not submitted</strong>
                    </span>
                </div>
    }

    const TableBody = ({list}) => {
        if (!list[0] || !list[0].student_name){
            return <tr><td colSpan={7} className='border-0 font-size-lg color-grey800 pt-4'>No data yet.</td></tr>
        }

        return list.map((item, index) => {
            let tooltip = {}
            if (item.speaking.state === "SubmittedNotGraded" || item.writing.state === "SubmittedNotGraded") {
                tooltip = {
                    "data-bs-toggle": "tooltip",
                    "data-bs-placement": "top",
                    "title": "Click to assess student work."
                }
            }
            return (<tr key={index} className="align-middle table-tr hover-modal" onClick={() => props.handleClick({id: item.student_id, name: item.student_name}, item.book_path)} {...tooltip}>
                <td className="color-grey900 font-weight-300">{item.date}</td>
                <td className="color-grey900 font-weight-300">{TotalSecondsFormat(item.total_time_spent_in_seconds)}</td>
                <td className="fw-bold text-start color-grey900">{item.student_name}</td>
                <td className="color-grey800 text-start text-truncate" style={{maxWidth: '250px'}}>
                    {item.book_path?<img src={actions.getCoverUrl(item.book_path)} alt="img" height="40px" className="me-2"/>: null}
                    {item.book_name}
                </td>
                <td className="color-grey800">
                    <GetScore type='comprehension' data={item.comprehension} />
                    {(item.comprehension.state !== 'HasPlayed')?<CustToolTip />:null}
                </td>
                <td>
                    <GetScore type='speaking' data={item.speaking} meta={item.book_meta} />
                </td>
                <td>
                    <GetScore type='writing' data={item.writing} meta={item.book_meta} />
                </td>
                {/* <td className='font-weight-500 color-grey800'>{item.overall}</td>  */}
            </tr>)
        })
    }

    return (
        <div>
            <Card className="p-3 overflow-auto">
                <SearchFilter />
                <Table className="text-center table-sw">
                    <thead className="bg-color curved-top">
                        <tr className="color-grey800">
                            <td colSpan={4} className="border-0"></td>
                            <td className="border-0" colSpan={4}>
                                Most recent scores
                                <div className="border-center"> </div>
                            </td>
                        </tr>
                        <tr className="color-grey800 font-weight-600">
                            <td>BOOK OPENED<br/><small>(Eastern Daylight Time)</small></td>
                            <td className="recent_table_duration">DURATION<br/><small>(Minutes)</small><br/><small>Sessions under 30-seconds are not shown</small></td>
                            <td></td>
                            <td></td>
                            <td>COMPREHENSION</td>
                            <td>SPEAKING</td>
                            <td>WRITING</td>
                            {/* <td>OVERALL</td> */}
                        </tr>
                    </thead>
                    <tbody>
                        <TableBody list={list} />
                    </tbody>
                </Table>
                <ReactPaginate
                    previousLabel={<span className="arrow-btn mx-2"><img src={process.env.PUBLIC_URL + '/images/right_arrow.svg'} width="7px"  className="rotate-180" alt='left arrow' /></span>}
                    nextLabel={<span className="arrow-btn mx-2"><img src={process.env.PUBLIC_URL + '/images/right_arrow.svg'} width="7px" alt='right arrow' /></span>}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination align-items-center justify-content-end  mt-3"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
            </Card>
        </div>

    )
}