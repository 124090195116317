import axios from "axios";
import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // console.log(error, 'info: ',errorInfo, 'stack: ', error.stack);
        let err = `FRONTEND
            Error msg: ${error.message}
            ${errorInfo.componentStack}*`;
        // console.log(err)

        // Sends notification to slack
        if (process.env.REACT_APP_SEND_NOTIFICATION === 'true') {
            axios.post(
                process.env.REACT_APP_SLACK_HOOK,
                JSON.stringify({ text: err }),
                {
                    withCredentials: false,
                    transformRequest: [
                        (data, headers) => {
                            delete headers.post["Content-Type"];
                            return data;
                        },
                    ],
                }
            );
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
