import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FormControl, Row, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import store from '../../store'
import * as actions from "../../actions"
import * as colorAPI from '../../api/color'
import 'chartjs-adapter-date-fns';

import { ScatterChartTime } from '../../components/Charts';
import GetScore from '../components/GetScore';

// Book detail page from Assigned book tab
export default function BookPage(props) {
    let data = store.getState().recent.assigned_book

    const [list, setList]                   = useState([])
    const [listNotOpened, setListNotOpened] = useState([])
    const [search, setSearch]               = useState('')
    const [sortBy, setSortBy]               = useState('NEWEST')

    useEffect(() => {
        setList(data.opened)
        setListNotOpened(data.not_opened)
    }, [data])

    useEffect(() => {
        setList(data.opened.filter(row => row['student_name'].toLowerCase().includes(search.toLowerCase())))
        setListNotOpened(data.not_opened.filter(row => row['student_name'].toLowerCase().includes(search.toLowerCase())))
    }, [search])

    if (data.length === 0 ) return (
        <>
            <Button size="sm" className="bg-transparent color-blue500 font-weight-600" onClick={props.goBack}><FontAwesomeIcon icon={faArrowLeft} /> All Books</Button>
            <div>Error Occured</div>
        </>
    )

    const handleChange = (event) => {
        setSearch(event.target.value)
    }

    const toggleSortBy = () => {
        if (sortBy === "NEWEST") setSortBy('OLDEST')
        else setSortBy('NEWEST')
        list.reverse();
	}

    let chart_data = data.comprehension_graph.graph_data.map(d => {
        return {
            label: d.student_name,
            data: [{x: d.time_spent, y: d.comprehension?d.comprehension:0}], // to make null 0
            backgroundColor: colorAPI.Comp_graph(d.comprehension),
            pointRadius: 8,
            pointHoverRadius: 8
        }
    });


    return (
        <Card className="">
            <Row className="py-5 mb-3" style={{paddingLeft: '5rem', paddingRight: '5rem'}}>
                <Col className="p-0">
                    <Button className="bg-transparent color-blue500 font-weight-600 mb-5" onClick={props.goBack}><FontAwesomeIcon icon={faArrowLeft} /> All Books</Button>
                    {/* Book detail */}
                    <div className="mt-3 mb-5 d-flex">
                        {data.book_meta.path?<img src={actions.getCoverUrl(data.book_meta.path)} alt="img" height="150px" className="me-2"/>:null}
                        <div className="d-flex flex-column justify-content-between ms-3">
                            <h5 className="mb-0 font-weight-600 font-size-xxxl color-grey900">{data.book_meta.title}</h5>
                            <div>
                                <div className="color-grey900 w-auto"><span className="d-inline-block" style={{width: "100px"}}>Assigned: </span><span>{data.assigned_date}</span></div>
                            </div>
                            <h6 className="color-grey800 mb-0">Level: {data.book_meta.book_level} &emsp;|&emsp; Lexile: {data.book_meta.lexile} &emsp;|&emsp;	F&P: {data.book_meta.fp}</h6>
                        </div>
                    </div>
                    {/* Download links */}
                    <div className='mb-4'>
                        <img src={process.env.PUBLIC_URL + '/images/download_icon.svg'} className="me-2" alt='download icon'/>
                        <a href={process.env.REACT_APP_PDF_URL+`${data.book_meta.path}/lessonplan/EN/${data.lesson_plan}`} target="_blank" rel="noopener noreferrer" className="text-decoration-none fw-bold color-blue500">Lesson Plan</a>
                    </div>
                    <div className='mb-4'>
                        <img src={process.env.PUBLIC_URL + '/images/download_icon.svg'} className="me-2" alt='download icon'/>
                        <a href={process.env.REACT_APP_PDF_URL+`${data.book_meta.path}/activity/${props.lang.split('-')[0]}/${data.work_sheet}`} target="_blank" rel="noopener noreferrer" className="text-decoration-none fw-bold color-blue500">Worksheet</a>
                    </div>
                    <div>
                        <img src={process.env.PUBLIC_URL + '/images/download_icon.svg'} className="me-2" alt='download icon'/>
                        <a href={process.env.REACT_APP_PDF_URL+`${data.book_meta.path}/proficiency/${props.lang.split('-')[0]}/${data.running_record}`} target="_blank" rel="noopener noreferrer" className="text-decoration-none fw-bold color-blue500">Running Record</a>
                    </div>
                </Col>
                {/* Total time scatter plot graph */}
                <Col>
                    <Card >
                        <ScatterChartTime chartData={chart_data} step={data.comprehension_graph.step} max={data.comprehension_graph.max} />
                        <span className="color-grey900 font-size-xs ms-6 text-center position-relative" style={{bottom: '20px'}}>Includes story & quizzes</span>
                    </Card>
                </Col>
            </Row>

            <div className="px-5">
                <Row className="align-items-center mx-3 mb-4">
                    <Col className='position-relative'>
                        <img src={process.env.PUBLIC_URL + '/images/search_icon.svg'} className="search-icon"  style={{left: "calc(15px + 1rem)"}} alt='search icon'/>
                        <FormControl
                            placeholder="Search student..."
                            aria-label="Username"
                            className="bg-color search-box color-grey700"
                            aria-describedby="basic-addon1"
                            style={{height: "48px"}}
                            onChange={handleChange}
                            value = {search}
                        />
                    </Col>
                </Row>
                <div className="table-responsive">
                <Table className="text-center table-sw">
                    <thead className="bg-color curved-top">
                        <tr className="color-grey700 font-weight-600">
                            <td onClick={toggleSortBy}>{sortBy}<img src={process.env.PUBLIC_URL + '/images/up_arrow.svg'} className={sortBy==='OLDEST'?'ms-2 rotate-180':'ms-2'} alt='up arrow'/></td>
                            <td></td>
                            <td></td>
                            <td>COMPREHENSION</td>
                            <td>SPEAKING</td>
                            <td>WRITING</td>
                            <td>TOTAL TIME</td>
                            {/* <td>OVERALL</td> */}
                        </tr>
                    </thead>
                    <tbody>
                        {/* List of opened books */}
                        {
                            list?
                            list.map((item, index) => {
                                return (<tr key={index} className="color-grey800 align-middle table-tr td hover-modal" onClick={() => props.handleClick({id: item.student_id, name: item.student_name}, item.book_path)}>
                                    <td className="font-weight-300 color-grey900">{item.date}</td>
                                    <td className="fw-bold text-start color-grey900">{item.student_name}</td>
                                    <td className="text-start text-truncate">
                                        <img src={actions.getCoverUrl(item.book_path)} alt="img" height="40px" className="me-2"/>
                                        {item.book_name}
                                    </td>
                                    <td className="color-grey800">
                                        <GetScore type='comprehension' data={item.comprehension} />
                                    </td>
                                    <td>
                                        <GetScore type='speaking' data={item.speaking} />
                                    </td>
                                    <td>
                                        <GetScore type='writing' data={item.writing} />
                                    </td>
                                    <td>{item.time_spent}</td>
                                    {/* <td className="font-weight-500">{item.overall}</td> */}
                                </tr>)
                            }): null
                        }
                        {/* List of not opened books */}
                        <tr><td className="pt-5 fw-bold color-grey700">NOT OPENED</td></tr>
                        {
                            listNotOpened?
                            listNotOpened.map((item, index) => {
                                return (<tr key={index} className="color-grey800 align-middle table-tr td">
                                    <td className=""></td>
                                    <td className="fw-bold text-start color-grey900">{item.student_name}</td>
                                    <td className="text-start">
                                        <img src={actions.getCoverUrl(item.book_path)} alt="img" height="40px" className="me-2"/>
                                        {item.book_name}
                                    </td>
                                    <td className=""><span className="ms-2">--</span></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>)
                            }): null
                        }
                    </tbody>
                </Table>
                </div>
            </div>
        </Card>
    )
}
