import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Dashboard from "./Dashboard";
import Phonics from "./Phonics";

export default () => (
  <Switch>
    <Route exact path={Routes.Dashboard.path} component={Dashboard} />
    <Route exact path={Routes.Phonics.path} component={Phonics} />
    <Redirect to='/' />
  </Switch>
);
