import {client} from '../api/client'
import * as actions from "../actions/actiontypes"
const initialState = null

const assignedBookReducer = (state = initialState, action)=> {
    switch(action.type) {
        case actions.AB_DEFAULT_LOAD:
        return action.payload
        default:
            return state;
    }
}
// Thunk function
export async function fetchAssignedBook(dispatch, getState) {
    const response = await client.get(process.env.REACT_APP_API_BASE_URL+'/api/assigned/books')
    dispatch({ type: actions.AB_DEFAULT_LOAD, payload: response.data })
}

export default assignedBookReducer;