import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Tab, Nav } from "@themesberg/react-bootstrap";
import pako from 'pako'
import LoadingOverlay from 'react-loading-overlay';

import store from '../store';
import * as actions from "../actions"
import * as actiontypes from "../actions/actiontypes"
import { TeacherEmail } from "../api/teacher";

import BookModalBody from "./StudentModal_components/BookModalBody";
import OverviewModalBody from "./StudentModal_components/OverviewModalBody";

// Modal when clicked on student or book
export default function StudentModal(props) {
    const lang = useSelector(state => store.getState().recent.req_data.target_lang);
    const data_overview = useSelector(state => store.getState().recent.modal_overview);
    const data_book = useSelector(state => store.getState().recent.modal_book);

	const [body, setBody] = useState('')

	const [student, setStudent] = useState(props.student)
	const [book, setBook] = useState(props.book)
	const [animation, setAnimation] = useState(false)	// For the sliding animation
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		setIsLoading(true)
		if (book){
			// Opens book modal if contains book name
			let writingCount = 0, writingCompleted = 0
			actions.getModalBook(TeacherEmail(), student.id, book, lang).then(resolve=>{
				store.dispatch({type:actiontypes.RA_MODAL_BOOK,payload: resolve.data})
			},error=>{
				console.log(error)
			}).then(()=>{
				const data = store.getState().recent.modal_book
				// Getting audio and text answers
				const promise = (s3key,item_type)=>{
					actions.fetchWritingResponseById(s3key, item_type).then(response=>{
						writingCompleted++
						store.dispatch({type:actiontypes.RA_READ_TEXT_RESPONSE,payload: pako.inflate(response.Body, { to: "string" }), key: item_type})
						if (writingCompleted === writingCount) {	// Open modal only when all audio are loaded
							setIsLoading(false)
							if (animation) gotoBookAnimation()	// Open book modal body with slide animation
							else setBody('book')				// Open book modal body without slide animation
						}
					}).catch(err => {
						setIsLoading(false)
						setBody('book')}
					)
				}
				try {
					for (const key in data.writing.writings) {
						if (data.writing.writings[key].text) writingCount++
					}
					if (data.writing.writings.write_recount.text) promise(data.writing.writings.write_recount.text, "recount")
					if (data.writing.writings.write_inference.text) promise(data.writing.writings.write_inference.text, "inference")
					if (data.writing.writings.write_opinion.text) promise(data.writing.writings.write_opinion.text, "opinion")
					if (writingCount === 0)  {
						setIsLoading(false)
						if (animation) gotoBookAnimation()		// Open book modal body with slide animation
						else setBody('book')					// Open book modal body without slide animation
					}
				} catch (error) {
					console.log(error)
				}
			},s3ex=>{
				console.log(s3ex)
			})
		} else if (student.id) {
			// Opens overview modal
			actions.getProfileModal(TeacherEmail(), student.id, lang).then(response=>{
				store.dispatch({type:actiontypes.RA_MODAL_OVERVIEW,payload: response.data})
				setIsLoading(false)
				if (animation) backToStudentAmination()			// Open overview modal body without slide animation
				else setBody('overview')						// Open overview modal body without slide animation
			},error=>{
				console.log(error)
			})
		}
	}, [student, book])

	const gotoBook = (book_name) => {
		setAnimation(true)
		setBook(book_name)
	}
	const backToStudent = () => {
		setAnimation(true)
		setBook('')
	}

	const gotoBookAnimation = () => {
		let bookAnimation = () => {
			setBody('book')
			setAnimation(false)
			document.getElementById('book-body').removeEventListener('animationend', bookAnimation)
		}

		document.getElementById('overview-body').className = 'slide-out-left'
		document.getElementById('book-body').className = 'slide-in-left'
		document.getElementById('book-body').addEventListener('animationend', bookAnimation)
	}

	const backToStudentAmination = () => {
		let studentAnimation = () => {
			setBody('overview')
			setAnimation(false)
			document.getElementById('overview-body').removeEventListener('animationend', studentAnimation)
		}

		document.getElementById('overview-body').className = 'slide-in-right'
		document.getElementById('book-body').className = 'slide-out-right'
		document.getElementById('overview-body').addEventListener('animationend', studentAnimation)
	}

	return (
		<div>
			<Modal
				show={props.modal_status}
				onHide={()=>props.onClose()}
				dialogClassName="modal-90w modal-min-width student-modal"
				size="xl"
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Tab.Container defaultActiveKey="overview">
					<Modal.Header closeButton className="bg-grey950">
						<span className={body === "overview" ? 'max-width1100 w-100 mx-auto d-flex align-items-center': 'container d-flex align-items-center'}>
							<Modal.Title className="col-6 ps-3 fw-bold color-white" style={{fontSize: '32px'}}>
								{student.name}
							</Modal.Title>
							<Nav fill variant="pills" className="flex-column flex-sm-row modal-tab col-5 me-auto font-size-lg fw-bold">
								<Nav.Item className="col-6">
									<Nav.Link eventKey="overview" className="mb-sm-3 mb-md-0 color-grey900">
										<span className="tab-text">Overview</span>
									</Nav.Link>
								</Nav.Item>
								{/* Note tab */}
								{/* <Nav.Item className="col-6">
									<Nav.Link eventKey="notes" className="mb-sm-3 mb-md-0 color-grey800">
										<span className="tab-text">Notes</span>
									</Nav.Link>
								</Nav.Item> */}
							</Nav>
						</span>
					</Modal.Header>
					<LoadingOverlay
						active={isLoading}
						spinner
						text='Loading content...'
						className='spinner'
					>
						<Modal.Body className="bg-color">
							<Tab.Content>
								<Tab.Pane eventKey="overview" className="m-4 p-0 modal-body" style={{minHeight: '800px'}}>
									{/* Loading both body for animation */}
									{
										body === "overview"?
											<>
												<span id='overview-body'>
													<OverviewModalBody data={data_overview} goto={gotoBook} student={student}/>
												</span>
												<span id='book-body' className='d-none'>
													<BookModalBody data={data_book} back={backToStudent} student={student}/>
												</span>
											</>
										:
										body === "book" ?
											<>
												<span id='overview-body' className='d-none'>
													<OverviewModalBody data={data_overview} goto={gotoBook} student={student}/>
												</span>
												<span id='book-body'>
													<BookModalBody data={data_book} back={backToStudent} student={student}/>
												</span>
											</>
										:
										<div>Loading data</div>

									}
								</Tab.Pane>
								{/* Note tab */}
								{/* <Tab.Pane eventKey="notes" className="py-4" style={{height: "600px"}}>
									Notes functionality not available in this account
								</Tab.Pane> */}
							</Tab.Content>
						</Modal.Body>
					</LoadingOverlay>
				</Tab.Container>
			</Modal>
		</div>
	);
}
