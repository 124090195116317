// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import axios from "axios";
import queryString from 'query-string';
export async function client(endpoint, { body, ...customConfig } = {}) {
    const headers = {
        "Content-Type": "application/json",
        withCredentials: true,
    };

    const config = {
        method: body ? "POST" : "GET",
		withCredentials: true,
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    };

    if (body) {
        config.body = JSON.stringify(body);
    }

    let data;
    try {
        const response = await axios(endpoint, config);
        data = await response.json();
        if (response.ok) {
            return data;
        }
        throw new Error(response.statusText);
    } catch (err) {
        return Promise.reject(err.message ? err.message : data);
    }
}

client.get = function (endpoint, customConfig = {}) {
	const getRequest = async () => {
		try {
			axios.defaults.withCredentials = true
			const response = await axios.get(endpoint, customConfig);
			return response.data
		} catch (err) {
			console.error(err);
		}
	};
	
	return getRequest()
    // return client(endpoint, { ...customConfig, method: "GET" });
};

client.post = function (endpoint, body, customConfig = {}) {
    const postRequest = async () => {
		try {
			axios.defaults.withCredentials = true
			const resp = await axios.post(endpoint, queryString.stringify(body));
			return resp.data
		} catch (err) {
			console.error(err);
		}
	}
	
	return postRequest()
	// return client(endpoint, body, { ...customConfig });
};
