import React from 'react'
import { Modal, Nav, Tab, Table } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import * as actions from "../../actions"
import * as colorAPI from '../../api/color'

import StudentReportPDF from './StudentReportPDF';

// Student report modal
export default function StudentReport(props) {
    let noOfBook
    if (props.data && props.data.reports) noOfBook = props.data.reports.length

    const PageHeader = () => {
        return <div className='report-header mb-5' id='reportHeader'>
            <div className='report-header-logo'>
                <img src={process.env.PUBLIC_URL + '/images/storyworld_logo.png'} width='75px' alt='storyworld logo'/>
            </div>
            <div className='report-header-studentName'>{props.student.name}</div>
            <div className='report-star' >
                <img src={process.env.PUBLIC_URL + '/images/star.svg'} width='60px' className='m-auto' alt='star' />
                <span className="report-star-text">{props.data.total_point}</span>
            </div>
        </div>
    }

    // Student report preview
    const Report = () => {
        return <div className="a4-size bg-white m-auto mb-4" id='pdf'>
            <PageHeader />
            <div>
                <Table className='report-table table-striped table-bordered mt-5 mx-auto'>
                    <thead>
                        <tr>
                            <td className="" style={{width: "50%"}}>Books Read - <b>{noOfBook}</b></td>
                            <td className="" style={{width: "25%"}}>Points</td>
                            <td className="" style={{width: "25%"}}>Comprehension</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.data.reports?
                            props.data.reports.map((row, index) => {
                                return <tr key={index}>
                                        <td className="fw-bold text-start d-flex align-items-center">
                                            <img src={actions.getCoverUrl(row.book_path)} alt="img" height="50px" className="me-2 p-0"/>
                                            <span className='text-wrap'>{row.book_name}</span>
                                        </td>
                                        <td className='position-relative'>
                                            <img src={process.env.PUBLIC_URL + '/images/star.svg'} width="45px" className='p-0' alt='star'/>
                                            <span className="text-icon-model fw-bold color-blue800" style={{top: "53%", left: "50%", fontSize: "12px"}}>
                                                {row.points}
                                            </span>
                                        </td>
                                        <td>
                                            <FontAwesomeIcon icon={faCircle} style={{fontSize: '1.5em'}} className={colorAPI.Comprehension(row.comprehension)} />
                                        </td>
                                    </tr>
                            }): null
                        }
                    </tbody>
                </Table>

                <div className='score-table m-auto'>
                    <img src={process.env.PUBLIC_URL + '/images/Scoreboard.svg'} width='500px' alt='scoreboard'/>
                </div>
            </div>
        </div>
    }

    return (
        <Modal
            show={props.modal_status}
            onHide={()=>props.onClose()}
            dialogClassName="modal-90w modal-min-width"
            size="xl"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Tab.Container defaultActiveKey="report">
                <Modal.Header closeButton className="bg-blue100">
                    <Modal.Title className="col-6 ps-3 fw-bold color-grey900">
                        {props.data.student_name} Report Card
                    </Modal.Title>
                    <Nav fill variant="pills" className="flex-column flex-sm-row modal-tab col-5 me-auto font-size-lg fw-bold">
                        <Nav.Item className="col-6">
                            <Nav.Link eventKey="report" className="mb-sm-3 mb-md-0 color-grey900">
                                <span className="tab-text">Report</span>
                            </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item className="col-6">
                            <Nav.Link eventKey="notes" className="mb-sm-3 mb-md-0 color-grey800">
                                <span className="tab-text">Notes</span>
                            </Nav.Link>
                        </Nav.Item> */}
                    </Nav>
                </Modal.Header>
                <Modal.Body className="bg-color">
                    <Tab.Content>
                        <Tab.Pane eventKey="report" className="py-4 px-4">
                            <StudentReportPDF student={props.student} total_point={props.data.total_point} reports={props.data.reports}/>
                            <Report />
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="notes" className="py-4" style={{height: "600px"}}>
                            Notes functionality not available in this account
                        </Tab.Pane> */}
                    </Tab.Content>
                </Modal.Body>
            </Tab.Container>
        </Modal>
    )
}
