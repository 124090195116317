import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import * as colorAPI from '../../api/color'

// Return score and icon for comprehension, writing and speaking
export default function GetScore({type, data, meta=null, isModalSummary=false, isBookmodal=false, comp_size='xs'}) {
    // If no data return --
    if (!data) return <span>--</span>
    // If wordbook return 'NA' for speaking and writing
    if (meta && meta.content === 'word-book' && (type === 'speaking' || type === 'writing')) return 'NA'

    // Comprehension played case
    if (type === 'comprehension' && data.state === 'HasPlayed') {
        return(<>
            <FontAwesomeIcon icon={faCircle} size={comp_size} className={colorAPI.Comprehension(data.avg)} />
            <span className="ms-2">{data.avg}%</span>
        </>)
    }

    if (type === 'speaking') {
        // Speaking submitted but not graded
        if (data.state === 'SubmittedNotGraded') {
            if (isModalSummary) {
                return <span>--</span>
            }
            return (
                <span className='position-relative'>
                    <img src={process.env.PUBLIC_URL + '/images/speaking_noNumber_icon.svg'} alt='speaking icon' />
                </span>
            )
        }
        // Speaking submitted and graded
        else if (data.state === 'SubmittedGraded') {
            if (isModalSummary) {   // Return different logo for modal
                return <>
                    <img src={process.env.PUBLIC_URL + '/images/speaking_icon_large.svg'} width="40px" alt='speaking icon'/>
                    <span className="text-icon-model color-blue500 fw-bold">{data.avg}</span>
                </>
            }
            return (
                <span className='position-relative'>
                    <img src={process.env.PUBLIC_URL + '/images/speaking_number_icon.svg'} alt='speaking icon' />
                    <span className="text-icon-speaking color-blue500 fw-bold" style={isBookmodal ? {top: '2px'}:null}>{data.avg}</span>
                </span>
            )
        }
    }

    if (type === 'writing') {
        // Writing submitted but not graded
        if (data.state === 'SubmittedNotGraded') {
            if (isModalSummary) {
                return <span>--</span>
            }
            return (
                <span className='position-relative'>
                    <img src={process.env.PUBLIC_URL + '/images/writing_noNumber_icon.svg'} alt='writing icon' />
                </span>
            )
        }
        // Writing submitted and graded
        else if (data.state === 'SubmittedGraded') {
            if (isModalSummary) {   // Return different logo for modal
                return <>
                    <img src={process.env.PUBLIC_URL + '/images/writing_number_icon_large.svg'} width="32px" alt='writing icon'/>
                    <span className="text-icon-model color-blue400 fw-bold" style={{top: "51%"}}>{data.avg}</span>
                </>
            }
            return (
                <span className='position-relative'>
                    <img src={process.env.PUBLIC_URL + '/images/writing_number_icon.svg'} alt='writing icon' />
                    <span className="text-icon-writing color-blue500 fw-bold" style={isBookmodal ? {top: '2px'}:null}>{data.avg}</span>
                </span>
            )
        }
    }
    return (
        <span>--</span>
    )
}
